// Third-party
import { useEffect, useState } from 'react';

// First-party
import { If, createComponent, toClassName } from '@lib/util/templateHelpers';
import { useResize } from '@lib/hooks/resize';


/* Generic ad containers */

const adClassStates = [
  'alignTop'
];

const Ad = createComponent('Ad', { classStates: adClassStates }, function Ad ({ className }, props) {
  return (
    <div id={props.id} className={className}>{props.children}</div>
  );
});
export default Ad; 

const adContainerClassStates = [
  'banner',
  'mobileBanner',
  'halfPage',
  'inlineRect',
  'largeLeaderboard',
  'largeRect',
  'leaderboard',
  'mediumRect',
  'mobileLeaderboard',
  'skyscraper',
  'smallSquare',
  'square',
  'wideSkyscraper'
];

Ad.Container = createComponent('AdContainer', {}, function AdContainer ({ mergeClassNames, style }, props) {
  const className = mergeClassNames(toClassName('AdContainer', adContainerClassStates, { [props.size]: true }));

  // const [ visible, setVisible ] = useState(props.breakpointVisible ? false : true);

  // useResize((width, height, checkBreakpoint) => {
  //   if (props.breakpointVisible) setVisible(checkBreakpoint(props.breakpointVisible));
  // });

  // useEffect(() => {
  //   if (props.provider) props.provider()
  // }, [visible]);

  return (
    // <>
    //   {
    //     If(visible, () => (
    //       <div className={className} style={style}>
    //         {props.children}
    //       </div>
    //     ))
    //     .EndIf()
    //   }
    // </>
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});

Ad.BANNER = 'banner';
Ad.MOBILE_BANNER = 'mobileBanner';
Ad.HALF_PAGE = 'halfPage';
Ad.LARGE_LEADERBOARD = 'largeLeaderboard';
Ad.LARGE_RECT = 'largeRect';
Ad.LEADERBOARD = 'leaderboard';
Ad.MEDIUM_RECT = 'mediumRect';
Ad.MOBILE_LEADERBOARD = 'mobileLeaderboard';
Ad.SKYSCRAPER = 'skyscraper';
Ad.SMALL_SQUARE = 'smallSquare';
Ad.SQUARE = 'square';
Ad.WIDE_SKYSCRAPER = 'wideSkyscraper';


/* Playwire */

Ad.Playwire = createComponent('AdPlaywire', {}, function AdPlaywire({}, props) {
  const subTypes = Array.isArray(props.subType)
    ? props.subType.join(',')
    : props.subType

  return (
    <div data-pw-desk={props.type} id={subTypes}></div>
  );
});

Ad.Playwire.Type = {};
Ad.Playwire.Type.DESKTOP_MEDRECT_ATF = 'med_rect_atf';
Ad.Playwire.Type.DESKTOP_MEDRECT_BTF = 'med_rect_btf';

Ad.Playwire.SubType = {};
Ad.Playwire.SubType.DESKTOP_MEDRECT_ATF = 'pwDeskMedRectAtf';
Ad.Playwire.SubType.DESKTOP_MEDRECT_BTF = 'pwDeskMedRectBtf';
