import { useEffect } from 'react';

import { createComponent } from '@lib/util/templateHelpers';

import Flex from './Flex';

export default createComponent('Layout', {}, function Layout ({ className, style }, props) {
  useEffect(() => {
    if (props.fillScreen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [props.fillScreen]);

  return (
    <Flex directionColumn fit className={className} style={style}>
      {props.children}
    </Flex>
  );
});
