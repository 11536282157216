import { useEffect, useState } from 'react';
import { createTeleporter } from 'react-teleporter';

import { createStore } from '@lib/util/store';

// Mutations

function SET_MODAL (state, modal) {
  return {
    ...state,
    modal
  };
}

function CLEAR_MODAL (state) {
  return {
    ...state,
    modal: null
  };
}

function SET_ENTER (state, transition) {
  return {
    ...state,
    enter: transition
  };
}

function SET_ENTER_FROM (state, transition) {
  return {
    ...state,
    enterFrom: transition
  };
}

function SET_LEAVE (state, transition) {
  return {
    ...state,
    leave: transition
  };
}

// Actions

function openModal (payload, mutate) {
  mutate(SET_MODAL, payload);
}

function closeModal (payload, mutate) {
  mutate(CLEAR_MODAL);
}

function setTransitions ({ enter, leave, enterFrom }, mutate) {
  if (enter) mutate(SET_ENTER, enter);
  if (leave) mutate(SET_LEAVE, leave);
  if (enterFrom) mutate(SET_ENTER_FROM, enterFrom);
}

// Store

const {
  Provider,
  useStore
} = createStore({
  defaultState: {
    modal: null,
    enter: null,
    enterFrom: null,
    leave: null
  }
});

let ModalTeleporter

// Main export

export default function useModal () {
  const [ state, dispatch ] = useStore();

  if (ModalTeleporter === undefined && typeof window !== 'undefined') {
    ModalTeleporter = createTeleporter({ multiSources: true });
  }

  const [ currentModal, setCurrentModal ] = useState(state.modal);
  useEffect(() => {
    setCurrentModal(state.modal);
  }, [state.modal]);

  const _openModal = (name, options = {}) => {
    if (state.modal?.options?.onClose) state.modal.options.onClose(state.modal);
    dispatch(openModal, { name, options })
  };

  const _closeModal = () => {
    if (state.modal?.options?.onClose) state.modal.options.onClose(state.modal);
    dispatch(closeModal);
  };

  const _setTransitions = (enter, leave, enterFrom) => {
    dispatch(setTransitions, { enter, leave, enterFrom });
  }

  return {
    state,
    currentModal,
    openModal: _openModal,
    closeModal: _closeModal,
    setTransitions: _setTransitions,
    Provider,
    Teleporter: ModalTeleporter
  };
}
