import { createComponent } from '@lib/util/templateHelpers';

const fieldStates = [
  'addons',
  'addonsCentered',
  'addonsRight',
  'addonsFullwidth',
  'grouped',
  'groupedCentered',
  'groupedRight',
  'groupedMultiline',
  'horizontal'
];

const Field = createComponent('Field', { classStates: fieldStates }, function Field ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
export default Field;

const fieldLabelStates = [
  'small',
  'medium',
  'large'
];

Field.Label = createComponent('FieldLabel', { classStates: fieldLabelStates }, function FieldLabel ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});

const fieldHelpStates = [
  'small',
  'medium',
  'large',
  'success',
  'warning',
  'error'
];

Field.Help = createComponent('FieldHelp', { classStates: fieldHelpStates }, function FieldHelp ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});

const fieldBodyLabelStates = [
  'small',
  'medium',
  'large'
];

Field.BodyLabel = createComponent('FieldLabel', { classStates: fieldBodyLabelStates }, function FieldLabel ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});

Field.Body = createComponent('FieldBody', {}, function FieldBody ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
