import '@/styles/app.scss';
import '@/styles/themes.scss';
import '@fontsource/maven-pro/500.css';
import '@fontsource/maven-pro/700.css';

import { useRef, useState } from 'react';
import shared from '@lib/components';
const { App } = shared;
const PUB_ID = 1025328;
const WEBSITE_ID = 75248;

import { MobileMenuProvider } from '@/stores/mobileMenu';
import { MenuProvider } from '@/stores/menu';
import { AuthProvider, useAuthStore, verify, ready } from '@/stores/auth';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

function _app ({ Component, pageProps }) {
  const [rampComponentLoaded, setRampComponentLoaded] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const router = useRouter();

  useEffect(() => {
    const loadAdScript = () => {
      if (!PUB_ID || !WEBSITE_ID) {
        console.log('Missing Publisher Id and Website Id');
        return;
      }
      //Call spaNewPage on initial load
      if (!rampComponentLoaded && firstLoad) {
        const renderUnits = () => {
          // ramp.que.push ensures that the functions called are executed when Ramp has finished loading.
          window.ramp.que.push(() => {
            ramp.spaNewPage()
          })
          setFirstLoad(false);
        };

        setRampComponentLoaded(true);
        window.ramp = window.ramp || {};
        window.ramp.que = window.ramp.que || [];
        window.ramp.passiveMode = true;

        // Load the Ramp configuration script
        const configScript = document.createElement("script");
        configScript.src = `https://cdn.intergient.com/1025328/75248/ramp.js`;
        document.body.appendChild(configScript); // Insert before closing </body> tag

        configScript.onload = renderUnits;
      }
      //Call spaNewPage on all route/page changes after initial load
      router.events.on('routeChangeComplete', (url) => {
          setFirstLoad(false);
          window.ramp.que.push(() => {
            ramp.spaNewPage()
          })
      });

      document.addEventListener('DOMContentLoaded', function () {
        const body = document.getElementsByTagName('body')[0]
        body.appendChild(configScript);
      });

      window.removeEventListener('scroll', loadAdScript);
      window.removeEventListener('mousemove', loadAdScript);
      window.removeEventListener('touchstart', loadAdScript);
    };

    window.addEventListener('scroll', loadAdScript);
    window.addEventListener('mousemove', loadAdScript);
    window.addEventListener('touchstart', loadAdScript);

    return () => {
      window.removeEventListener('scroll', loadAdScript);
      window.removeEventListener('mousemove', loadAdScript);
      window.removeEventListener('touchstart', loadAdScript);
    }
  }, []);

  return (
    <MobileMenuProvider>
    <MenuProvider>
    <App.Provider
    authProvider={AuthProvider}
    >
    <App
    theme='iogames'
    readyAction={ready}
    verifyAction={verify}
    useAuthStore={useAuthStore}
    PUB_ID="1025328"
    WEBSITE_ID="75248"
    >
    <Component {...pageProps} />
    </App>
    </App.Provider>
    </MenuProvider>
    </MobileMenuProvider>
  );
}

export default _app;
