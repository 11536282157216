import { useState, useEffect } from 'react';
import { If, For, createComponent } from '@lib/util/templateHelpers';
import Field from './Field';
import Control from './Control';

const checkboxStates = [
  'error',
  'small',
  'medium',
  'large'
];

const Checkbox = createComponent('Checkbox', { classStates: checkboxStates }, function Checkbox ({ className, style }, props) {
  const [ checked, setChecked ] = useState(props.value === props.trueValue);
  
  const change = () => {
    let trueValue = props.trueValue;
    if (trueValue === undefined || trueValue === null) trueValue = true;

    let falseValue = props.falseValue;
    if (falseValue === undefined || falseValue === null) falseValue = true;

    const nextChecked = !checked;

    if (props.onChange) {
      if (nextChecked) {
        props.onChange(trueValue);
      } else {
        props.onChange(falseValue);
      }
    }

    setChecked(nextChecked);
  };

  useEffect(() => {
    setChecked(props.value === props.trueValue);
  }, [props.value, props.trueValue, props.falseValue]);

  return (
    <label 
      className={className} 
      style={style}
      readOnly={props.readOnly}
      disabled={props.disabled}
    >
      <input
        type='checkbox'
        readOnly={props.readOnly}
        disabled={props.disabled}
        onClick={change}
        defaultChecked={checked}
      />
      <span />
      <span>{props.children}</span>
    </label>
  );
});
export default Checkbox;

Checkbox.Field = createComponent('CheckboxField', {}, function CheckboxField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      {
        If(slots.label, () => (
          <Field.Label>{slots.label}</Field.Label>
        ))
        .EndIf()
      }
      <Control>
        <Checkbox
          key={key}
          value={form.data[key]}
          trueValue={schema.trueValue}
          falseValue={schema.falseValue}
          error={!!form.errors[key]} 
          readOnly={props.readOnly}
          disabled={props.disabled}
          onChange={(value) => form.validateField(key, value)}
        >
          {slots.description}
        </Checkbox>
        {
          If(form.errors[key], () => (
            <Field.Help error>{form.errors[key]}</Field.Help>
          ))
          .ElseIf(slots.help, () => (
            <Field.Help>{slots.help}</Field.Help>
          ))
          .EndIf()
        }
      </Control>
    </Field>
  )
});

Checkbox.Field.List = createComponent('CheckboxFieldList', {}, function CheckboxFieldList ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  const value = (value) => {
    return form.data[key].includes(value)
      ? value
      : false;
  };

  const change = (value, trueValue) => {
    const list = form.data[key];
    if (value) {
      list.push(trueValue);
    } else {
      const index = list.indexOf(trueValue);
      list.splice(index, 1);
    }
    form.validateField(key, list);
  };

  const disabled = (trueValue) => {
    const list = form.data[key];
    const max = schema.maxAllowed || Number.MAX_SAFE_INTEGER;
    return props.disabled || (!list.includes(trueValue) && list.length >= max);
  };

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      <Field.Label>{slots.label}</Field.Label>
        {
          For(schema.acceptedValues, (item) => (
            <Control key={`${key}_${item.value}`}>
              <Checkbox
                value={value(item.value)}
                trueValue={item.value}
                falseValue={false}
                error={!!form.errors[key]}
                readOnly={props.readOnly}
                disabled={disabled(item.value)}
                onChange={(value) => change(value, item.value)}
              >
                {item.label}
              </Checkbox>
            </Control>
          ))
        }
        <Control>
          {
            If(form.errors[key], () => (
              <Field.Help error>{form.errors[key]}</Field.Help>
            ))
            .ElseIf(slots.help, () => (
              <Field.Help>{slots.help}</Field.Help>
            ))
            .EndIf()
          }
        </Control>
    </Field>
  )
});
