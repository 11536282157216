export function slideUpOut (opts = {}) {
  return {
    opacity: {
      value: 0,
      easing: 'linear'
    },
    translateY: {
      value: '-50%',
      easing: 'easeOutCubic'
    },
    duration: opts.duration || 350
  }
}

export function slideUpIn (opts = {}) {
  return {
    opacity: {
      value: 1,
      easing: 'linear'
    },
    translateY: {
      value: '0%',
      easing: 'easeOutCubic'
    },
    duration: opts.duration || 350
  }
}

export function slideUpEnterFrom () {
  return {
    opacity: 0,
    translateY: '50%'
  }
}
