export function hasTouchScreen () {
  if (typeof window === undefined) {
    return false;
  }

  if ('maxTouchPoints' in navigator) {
    return navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    return navigator['msMaxTouchPoints'] > 0;
  } else {
    const mediaQuery = window.matchMedia && matchMedia('(pointer:coarse)');
    if (mediaQuery && mediaQuery.media === '(pointer:coarse)') {
      return !!mediaQuery.matches;
    } else if ('orientation' in window) {
      return true; // deprecated, but good fallback
    }
  }

  return false;
}

export function isMobileDevice () {
  if (typeof window === undefined) {
    return false;
  }

  const ua = navigator.userAgent;
  const isMobileUA = /Mobile|Android|iPhone|iPad|iPod/i.test(ua);

  return (
    hasTouchScreen() && 
    isMobileUA &&
    window.innerWidth === window.screen.availWidth
  );
}

export function isMobileBreakpoint () {
  return window.innerWidth <= process.env.APP_CONFIG.BREAKPOINTS[process.env.APP_CONFIG.MOBILE_BREAKPOINT];
}
