import { useRef } from 'react';

import { If, createComponent } from '@lib/util/templateHelpers';
import useLoadScripts from '@lib/hooks/loadScripts';
import Field from './Field';
import Control from './Control';

const THEME_LIGHT = 'light';
const THEME_DARK = 'dark';

const Recaptcha = createComponent('Recaptcha', {}, function Recaptcha ({ className, style }, props) {
  const theme = props.theme || THEME_LIGHT;
  const recaptcha = useRef(null);

  const verify = (responseToken) => {
    if (props.onSuccess) props.onSuccess(responseToken);
  };

  const error = (err) => {
    if (props.onError) props.onError(err);
  };

  const render = () => {
    recaptcha.current.render('recaptchaEl', {
      sitekey: props.siteKey,
      theme,
      callback: verify,
      'error-callback': error
    });
  };

  if (typeof window !== 'undefined') {
    window.recaptchaLoaded = () => {
      recaptcha.current = window.grecaptcha;
      render();
    };
    useLoadScripts(['https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit']);
  }

  return (
    <div className={className} style={style} id='recaptchaEl' />
  );
});
export default Recaptcha;

Recaptcha.Field = createComponent('RecaptchaField', {}, function RecaptchaField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;

  const success = (token) => {
    form.validateField(key, token);
  };

  const error = (err) => {
    if (process.env.NODE_ENV === 'development') console.warn('Recaptcha error', err);
    form.validateField(key, false);
  };

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      <Control>
        <Recaptcha
          theme={props.theme}
          siteKey={props.siteKey}
          onSuccess={success}
          onError={error} 
        />
        {
          If(form.errors[key], () => (
            <Field.Help error>{form.errors[key]}</Field.Help>
          ))
          .ElseIf(slots.help, () => (
            <Field.Help>{slots.help}</Field.Help>
          ))
          .EndIf()
        }
      </Control>
    </Field>
  );
});

Recaptcha.THEME_LIGHT = THEME_LIGHT;
Recaptcha.THEME_DARK = THEME_DARK;
