export default function ComScore ({ clientId }) {
  const script = `var _comscore = _comscore || [];
_comscore.push({ c1: "2", c2: "${clientId}" });

(function() {
  var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
  s.src = "https://sb.scorecardresearch.com/cs/${clientId}/beacon.js";
  el.parentNode.insertBefore(s, el);
})();`;

  return (
    <>
      <script type='application/javascript' dangerouslySetInnerHTML={{ __html: script }} />
      <noscript>
        <img src={`https://sb.scorecardresearch.com/p?c1=2&c2=${clientId}&cv=3.6&cj=1`} alt='' />
      </noscript>
    </>
  );
}
