import { createStore, CACHE_STRATEGY_LOCAL } from '@lib/util/store';
import { useRouter } from 'next/router';
import apiInstance from '@/apis/instances/drupal';
import authApi from '@/apis/auth';
import userApi from '@/apis/user';

function setToken (token) {
  apiInstance.defaults.headers['X-CSRF-Token'] = token;
}

function clearToken () {
  delete apiInstance.defaults.headers['X-CSRF-Token'];
}

// Mutations

function SET_AUTH (state, auth) {
  return {
    ...state,
    auth,
    ready: false
  }
}

function SET_READY (state, ready) {
  return {
    ...state,
    ready
  }
}

function VERIFY_AUTH (state, { user, token }) {
  return {
    ...state,
    user,
    token,
    ready: true
  }
}

function REFRESH_USER (state, { user }) {
  return {
    ...state,
    user,
  }
}

function UNSET_AUTH (state) {
  return {
    ...state,
    auth: null,
    user: null,
    token: null,
    ready: true
  }
}

// Actions

export async function authorize (options, mutate) {
  const error = (err) => {
    clearToken();
    mutate(UNSET_AUTH);
    if (options?.onError) options.onError(err)
  };

  const loginResult = await authApi.login(options.username, options.password);
  if (loginResult.error) return error(loginResult.error);

  mutate(SET_AUTH, {
    uid: loginResult.current_user.uid,
    csrfToken: loginResult.csrf_token, 
    logoutToken: loginResult.logout_token
  });
}

export async function verify (options, mutate, state) {
  const reset = () => {
    clearToken();
    mutate(UNSET_AUTH);
  };

  const error = (err) => {
    reset();
    if (options?.onError) options.onError(err)
  };

  const verify = await authApi.verify();
  if (verify.error) return error(verify.error);
  if (!verify) return reset();

  const token = await authApi.token();
  if (token.error) return error(token.error);
  setToken(token);

  const user = await userApi.getByUid(state.auth.uid)
  if (user.error) return error(user.error);

  mutate(VERIFY_AUTH, { user: user.data[0], token });
}

export async function refreshUser (options, mutate, state) {
  const user = await userApi.getByUid(state.auth.uid)
  if (user.error) return error(user.error);

  mutate(REFRESH_USER, { user: user.data[0] });
}

export async function ready (options, mutate) {
  mutate(SET_READY, true);
}

export async function deauthorize (options, mutate, state) {
  mutate(SET_READY, false);

  clearToken();
  mutate(UNSET_AUTH);
  if (options.onLogout) options.onLogout();

  if (state.auth.logoutToken) {
    const result = await authApi.logout(state.auth.logoutToken);
    if (result.error && options?.onError) options.onError(result.error);
  }
}

// Export

export const {
  Provider: AuthProvider,
  useStore: useAuthStore
} = createStore({
  defaultState: {
    user: null,
    auth: null,
    token: null,
    ready: false
  },
  cacheKey: 'auth',
  cacheStrategy: CACHE_STRATEGY_LOCAL
});
