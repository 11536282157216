import { createComponent } from '@lib/util/templateHelpers';

const hamburgerStates = [
  'rounded'
];

export default createComponent('Hamburger', { classStates: hamburgerStates }, function Hamburger ({ className, style }, props) {
  return (
    <div className={className} style={style} onClick={props.onClick}>
      <span />
    </div>
  );
});
