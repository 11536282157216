import { useRef } from 'react';

import { If, createComponent, toClassName } from '@lib/util/templateHelpers';
import useInput from '@lib/hooks/input';
import Field from './Field';
import Control from './Control';
import { toBase64 } from '@lib/util/toBase64';

const ANY = '';
const COLOR = 'color';
const DATE = 'date';
const DATE_TIME = 'dateTime';
const DECIMAL = 'decimal';
const EMAIL = 'email';
const FILE = 'file';
const HIDDEN = 'hidden';
const NUMBER = 'number';
const MONTH = 'month';
const PASSWORD = 'password';
const PHONE_NUMBER = 'phoneNumber';
const SEARCH = 'search';
const TIME = 'time';
const URL = 'url';
const WEEK = 'week';

const FILE_FORMAT_FORMDATA = 'formData';
const FILE_FORMAT_BASE64 = 'base64';

function getAttrs (type, props) {
  const params = {
    type: 'text',
    inputMode: null,
    attrs: {}
  }

  switch (type) {
    case COLOR:
      params.type = 'color';
      break;

    case DATE:
      params.type = 'date';
      break;

    case DATE_TIME:
      params.type = 'datetime-local';
      break;

    case DECIMAL:
      params.type = 'number';
      params.inputMode = 'decimal';
      params.attrs = {
        min: props.min || 0,
        max: props.max || Number.MAX_VALUE,
        step: props.step || 1
      };
      break;

    case EMAIL:
      params.type = 'email';
      params.inputMode = 'email';
      break;

    case FILE:
      params.type = 'file';
      params.attrs = {
        multiple: props.multiple || props.numFiles > 1
      };
      let accept = props.accept;
      if (accept && !Array.isArray(accept)) accept = [ accept ];
      if (accept) params.attrs.accept = accept.join(',');
      break;

    case HIDDEN:
      params.type = 'hidden';
      break;

    case NUMBER:
      params.type = 'number';
      params.inputMode = 'numeric';
      params.attrs = {
        min: props.min || 0,
        max: props.max || Number.MAX_SAFE_INTEGER,
        step: props.step || 1
      };
      break;

    case MONTH:
      params.type = 'month';
      break;

    case PASSWORD:
      params.type = 'password';
      break;

    case PHONE_NUMBER:
      params.type = 'tel';
      params.inputMode = 'tel';
      break;

    case TIME:
      params.type = 'time';
      break;

    case SEARCH:
      params.type = 'search';
      params.inputMode = 'search';
      break;

    case URL:
      params.inputMode = 'url';
      break;

    case WEEK:
      params.type = 'week';
      break;
  }

  return params;
}

const inputStates = [
  'rounded'
];

const Input = createComponent('Input', { classStates: inputStates }, function Input ({ mergeClassNames, style }, props) {
  const {
    states,
    focus,
    blur,
    change,
    keyPress
  } = useInput(props);

  const inputRef = useRef(null);

  const className = mergeClassNames(toClassName('Input', states, props));

  const { type, inputMode, attrs } = getAttrs(props.type, props);

  const fileData = useRef(undefined);

  const onChange = async (e) => {
    if (props.type === FILE) {
      const files = e.target.files;
      const numFiles = props.multiple
        ? -1
        : (props.numFiles || 1);

      const limit = numFiles === -1
        ? files.length
        : numFiles;

      if (!props.fileFormat) return files.slice(0, limit);

      if (props.fileFormat === FILE_FORMAT_FORMDATA) fileData.current = new FormData();
      if (props.fileFormat === FILE_FORMAT_BASE64) fileData.current = [];

      const reader = new FileReader();

      for (let i = 0; i < limit; i++) {
        const file = files[i];
        const fileName = file.name.replace(/\s/g, '_');

        if (props.maxFileSize && file.size > props.maxFileSize) {
          inputRef.current.value = null;
          if (props.onChange) props.onChange(null);

          return props.onFileError('size_too_big');
        }

        if (props.imageSize || props.videoSize) {
          const result = await new Promise((resolve, reject) => {
            reader.readAsDataURL(file);
            reader.onerror = function (err) {
              notify('File is the wrong type or corrupt.');
              reject(err)
            }
            reader.onload = function (e) {
              const mediaEl = document.createElement(props.videoSize ? 'video' : 'img');
              mediaEl[props.videoSize? 'onloadeddata' : 'onload'] = function () {
                if (props.videoSize) {
                  const ratio = props.videoSize[1] / props.videoSize[0];
                  const mediaRatio = this.videoHeight / this.videoWidth;
                  const ratioVariancePct = 0.1;

                  const sizeMatches = this.videoWidth >= props.videoSize[0] && this.videoHeight >= props.videoSize[1];
                  // offset gives a bit of room for slightly off sizes
                  const ratioMatches = ratio >= mediaRatio - ratioVariancePct || ratio <= mediaRatio + ratioVariancePct; 

                  resolve(sizeMatches && ratioMatches)
                } else {
                  resolve(this.width === props.imageSize[0] && this.height === props.imageSize[1])
                }
              };
              mediaEl.onerror = function (err) {
                notify('Error loading media.');
                reject(err)
              }
              mediaEl.src = e.target.result;
            };
          })
          if (!result) {
            inputRef.current.value = null;
            if (props.onChange) props.onChange(null);

            if (props.videoSize) {
              props.onFileError('incorrect_video_dimensions');
            } else {
              props.onFileError('incorrect_image_dimensions');
            }

            return;
          }
        }

        if (props.fileFormat === FILE_FORMAT_FORMDATA) fileData.current.append(fileName, file);
        if (props.fileFormat === FILE_FORMAT_BASE64) fileData.current.push({ name: fileName, data: file });
      }
      
      if (props.fileFormat === FILE_FORMAT_BASE64) {
        for (let i = 0; i < fileData.current.length; i++) {
          fileData.current[i].data = await toBase64(fileData.current[i].data);
        }
      }

      if (props.onChange) props.onChange(fileData.current);
    } else {
      change(e);
    }
  };

  const onBlur = (e) => {
    if (props.type === FILE) {
      if (props.onBlur) props.onBlur(fileData.current);
    } else {
      blur(e);
    }
  };

  return (
    <input
      type={type}
      ref={inputRef}
      inputMode={inputMode}
      className={className} 
      style={style}
      value={props.value}
      readOnly={props.readOnly}
      disabled={props.disabled}
      onFocus={focus}
      onBlur={onBlur}
      onChange={onChange}
      onKeyUp={keyPress}
      onKeyDown={keyPress}
      onKeyPress={keyPress}
      placeholder={props.placeholder}
      {...attrs}
    />
  );
});
export default Input;

Input.Field = createComponent('InputField', {}, function InputField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  const attrs = {};
  if (typeof schema?.minValue === 'number') attrs.min = schema.minValue;
  if (typeof schema?.maxValue === 'number') attrs.max = schema.maxValue;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      <Field.Label>{slots.label}</Field.Label>
      <Control iconsRight={!!slots.icon}>
        <Input
          {...props}
          key={key}
          type={props.type}
          value={form.data[key]} 
          error={!!form.errors[key]} 
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled}
          step={props.step}
          {...attrs}
          onChange={(value) => form.setField(key, value)}
          onFocus={() => form.resetErrors(true)}
          onBlur={(value) => form.validateField(key, value)}
          onKeyUp={props.onKeyUp}
          onKeyDown={props.onKeyDown}
          onKeyPress={props.onKeyPress}
        />
        {
          If(form.errors[key], () => (
            <Field.Help error>{form.errors[key]}</Field.Help>
          ))
          .ElseIf(slots.help, () => (
            <Field.Help>{slots.help}</Field.Help>
          ))
          .EndIf()
        }
        {slots.icon}
      </Control>
    </Field>
  )
});

Input.UncontrolledField = createComponent('InputField', {}, function InputUncontrolledField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  const attrs = {};
  if (typeof schema?.minValue === 'number') attrs.min = schema.minValue;
  if (typeof schema?.maxValue === 'number') attrs.max = schema.maxValue;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      <Field.Label>{slots.label}</Field.Label>
      <Control iconsRight={!!slots.icon}>
        <Input
          {...props}
          key={key}
          type={props.type}
          error={!!form.errors[key]} 
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled}
          step={props.step}
          {...attrs}
          onChange={(value) => form.setField(key, value)}
          onFocus={() => form.resetErrors(true)}
          onBlur={(value) => form.validateField(key, value)}
          onKeyUp={props.onKeyUp}
          onKeyDown={props.onKeyDown}
          onKeyPress={props.onKeyPress}
        />
        {
          If(form.errors[key], () => (
            <Field.Help error>{form.errors[key]}</Field.Help>
          ))
          .ElseIf(slots.help, () => (
            <Field.Help>{slots.help}</Field.Help>
          ))
          .EndIf()
        }
        {slots.icon}
      </Control>
    </Field>
  )
});

Input.ANY = ANY;
Input.COLOR = COLOR;
Input.DATE = DATE;
Input.DATE_TIME = DATE_TIME;
Input.DECIMAL = DECIMAL;
Input.EMAIL = EMAIL;
Input.FILE = FILE;
Input.HIDDEN = HIDDEN;
Input.NUMBER = NUMBER;
Input.MONTH = MONTH;
Input.PASSWORD = PASSWORD;
Input.PHONE_NUMBER = PHONE_NUMBER;
Input.SEARCH = SEARCH;
Input.TIME = TIME;
Input.URL = URL;
Input.WEEK = WEEK;

Input.FILE_ACCEPT_IMAGE = 'image/*';
Input.FILE_ACCEPT_VIDEO = 'video/*';
Input.FILE_ACCEPT_AUDIO = 'audio/*';

Input.FILE_FORMAT_FORMDATA = FILE_FORMAT_FORMDATA;
Input.FILE_FORMAT_BASE64 = FILE_FORMAT_BASE64;
