import axios from 'axios';

const instance = axios.create();

// Defaults
instance.defaults.baseURL = process.env.NEXT_PUBLIC_DRUPAL_API_URL;
instance.defaults.withCredentials = true;
// instance.defaults.headers = {};

// Interceptors
instance.interceptors.response.use(function (response) {
  if (response.data !== undefined) {
    return response.data;
  }
  return response;
});

export default instance;

export const jsonApiHeaders = {
  'Accept': 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json'
};
