import { toClassName, createComponent } from '@lib/util/templateHelpers';

const containerClassStates = [
  'noPadding'
];

export default createComponent('Container', { classStates: containerClassStates }, function Container ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      <div className={toClassName('Container__Inner', [ 'wide' ], props)}>
        {props.children}
      </div>
    </div>
  );
});
