import { If, createComponent } from '@lib/util/templateHelpers';
import Flex from './Flex';

const loaderStates = [
  'large'
];

const Loader = createComponent('Loader', { classStates: loaderStates }, function Loader ({ className, style }, props) {
  return (
    <Flex center className={className} style={style}>
      {props.children}
    </Flex>
  );
});
export default Loader;

Loader.Fill = createComponent('LoaderFill', {}, function LoaderFill ({ className, style }, props) {
  return (
    <Flex fit fill alignCenter justifyCenter className={className} style={style}>
      <Flex center>
        {
          If(props.children, () => (
            <>{props.children}</>
          ))
          .Else(() => (
            <Loader large />
          ))
          .EndIf()
        }
      </Flex>
    </Flex>
  );
});
