export function randomRange (min, max) {
  if (max === undefined) {
    max = min
    min = -max
  }
  return Math.random() * (max - min) + min
}

export function seededRandom (seed) {
  const random = () => {
    const x = Math.sin(seed++)
    return x - Math.floor(x)
  }

  const range = (min, max) => {
    if (max === undefined) {
      max = min
      min = -max
    }
    return random() * (max - min) + min
  }

  return [
    random,
    range
  ]
}
