import uniqid from 'uniqid';
import { createStore } from '@lib/util/store';

const LIFE_SPAN = 2000

// Mutations

function PUSH (state, payload) {
  const notifications = [ ...state.notifications ];
  notifications.push(payload);

  return {
    notifications
  };
}

function SPLICE (state, index) {
  const notifications = [ ...state.notifications ];
  notifications.splice(index, 1);

  return {
    notifications
  };
}

// Actions

function showNotification (payload, mutate, state) {
  const { message, lifeSpan } = payload;

  mutate(PUSH, {
    id: uniqid(),
    message,
    aliveFor: state.notifications.length 
      ? (lifeSpan / 2) 
      : lifeSpan
  });
}

function dismissNotification (payload, mutate) {
  mutate(SPLICE, payload);
}

// Store

const {
  Provider,
  useStore
} = createStore({
  defaultState: {
    notifications: []
  }
});

// Main export

export default function useNotifications ({ lifeSpan } = { lifeSpan: LIFE_SPAN }) {
  const [ state, dispatch ] = useStore();

  const notify = (message) => {
    dispatch(showNotification, { message, lifeSpan });
  };

  const dismiss = (i) => {
    dispatch(dismissNotification, i);
  };

  return {
    state,
    notify,
    dismiss,
    Provider
  };
}
