import React, { useState, useEffect, useRef } from 'react';
import anime from 'animejs';
import { If, createComponent } from '@lib/util/templateHelpers';

const Transition = createComponent('Transition', {}, function Transition ({ className, style }, props) {
  const [ visible, setVisible ] = useState(props.visible);
  const initialRender = useRef(!props.initial);
  const anim = useRef(null);
  const el = useRef(null);

  style = {
    ...style,
    visibility: 'hidden'
  };

  const reset = async () => {
    anim.current = anime({
      targets: el.current,
      ...(props.enterFrom || props.leave),
      duration: 0,
      easing: 'linear'
    });
    await anim.current.finished;
  };

  const show = async (doReset) => {
    el.current.style.visibility = 'hidden';
    if (anim.current) {
      anim.current.pause();
      anim.current.remove(el.current);
    }
    if (doReset) await reset();

    el.current.style.visibility = 'visible';
    anim.current = anime({
      targets: el.current,
      ...(props.enter || { duration: 0 })
    });
  };
  useEffect(() => {
    if (initialRender.current) return;
    if (!visible) return;
    show(true);
  }, [ visible ])

  const hide = async () => {
    if (anim.current) {
      anim.current.pause();
      anim.current.remove(el.current);
    }
    anim.current = anime({
      targets: el.current,
      ...(props.leave || { duration: 0 })
    });
    await anim.current.finished;
    setVisible(false);
  };

  useEffect(() => {
    if (initialRender.current) {
      if (!el.current) return;
      if (!props.visible) {
        el.current.style.visibility = 'hidden';
      } else {
        el.current.style.visibility = 'visible';
      }
      return;
    }
    if (props.visible) {
      if (visible) show();
      else setVisible(true);
    } else {
      hide();
    }
  }, [props.visible]);

  useEffect(() => {
    if (props.initial) reset();
    requestAnimationFrame(() => {
      initialRender.current = false;
    });
  }, []);

  return (
    <>
      {
        If(visible, () => (
          <div ref={el} className={className} style={style}>
            {props.children}
          </div>
        ))
        .EndIf()
      }
    </>
  );
});
export default Transition;

function getTargets (els, selector) {
  return Array
    .from(els)
    .map((el) => {
      if (el.matches(selector)) return el
      return el.querySelector(selector)
    });
}

// Flattens all child elements into a single list
function flatten (children, flat = []) {
  flat = [ ...flat, ...React.Children.toArray(children) ];

  if (children.props && children.props.children) {
    return flatten(children.props.children, flat)
  }

  return flat;
}

// Strips all circular references and internal fields
function simplify (children) {
  const flat = flatten(children);

  return flat
    .map(
      ({
        key,
        ref,
        type,
        props: {
          children,
          ...props
        }
      }) => ({
        key, ref, type, props
      })
    )
    .filter((child) => child.key.includes('.$'));
}

Transition.List = createComponent('TransitionList', {}, function TransitionList ({ className, style }, props) {
  const [ visible, setVisible ] = useState(props.visible);
  const initialRender = useRef(!props.initial);
  const anim = useRef(null);
  const el = useRef(null);
  const [ children, setChildren ] = useState(props.children);

  style = { 
    ...style,
    visibility: 'hidden'
  };

  const updateKeys = () => {
    const childEls = Array.from(el.current.querySelectorAll(props.target));
    const childComponents = simplify(children);
    childEls.forEach((el, i) => {
      el.setAttribute('data-transition-key', childComponents[i].key.replace('.$', ''));
    });
  }

  // Animate show
  const reset = async () => {
    const els = Array.from(el.current.querySelectorAll(props.target));
    anim.current = anime({
      targets: els,
      ...(props.enterFrom || props.leave),
      duration: 0,
      easing: 'linear'
    });
    await anim.current.finished;
  };

  const show = async (doReset) => {
    const els = Array.from(el.current.querySelectorAll(props.target));
    if (!els.length) return;

    el.current.style.visibility = 'hidden';
    if (anim.current) {
      anim.current.pause();
      anim.current.remove(el.current);
    }
    if (doReset) await reset();

    el.current.style.visibility = 'visible';
    anim.current = anime({
      targets: els,
      ...(props.enter || { duration: 0 }),
      delay: anime.stagger(props.delay || props.enter.duration / els.length)
    });
  };

  useEffect(() => {
    if (initialRender.current) return;
    if (!visible) return;

    if (el.current) {
      updateKeys();
      show(true);
    }
  }, [ visible ]);

  // Animate hide
  const hide = async () => {
    const els = Array.from(el.current.querySelectorAll(props.target));
    if (!els.length) return;

    if (anim.current) {
      anim.current.pause();
      anim.current.remove(el.current);
    }
    anim.current = anime({
      targets: els,
      ...(props.leave || { duration: 0 }),
      delay: anime.stagger(props.delay || props.leave.duration / els.length)
    });
    await anim.current.finished;
    setVisible(false);
  };

  // Animate additions/deletions
  const showAdded = async (els, children) => {
    els = Array.from(els).filter((el) => el instanceof HTMLElement);
    if (!els.length) return;
    
    const childEls = Array.from(el.current.querySelectorAll(props.target));
    const childComponents = simplify(children);
    childEls.forEach((el, i) => {
      if (childComponents[i]?.key) {
        el.setAttribute('data-transition-key', childComponents[i].key.replace('.$', ''));
      }
    });

    await anime({
      targets: els,
      ...(props.enterFrom || props.leave),
      duration: 0,
      easing: 'linear'
    }).finished;
    anime({
      targets: els,
      ...(props.enter || { duration: 0 })
    });
  };
  const hideRemoved = async (els, children) => {
    els = Array.from(els);
    if (!els.length) return;

    await anime({
      targets: els,
      ...(props.leave || { duration: 0 })
    }).finished;

    setChildren(children);
  };

  // Assign initial keys and watch for elements added
  useEffect(() => {
    let observer;
    if (el.current) {
      updateKeys();

      observer = new MutationObserver((mutationList) => {
        mutationList.forEach((mutation) => {
          switch(mutation.type) {
            case 'childList':
              if (mutation.addedNodes) {
                showAdded(getTargets(mutation.addedNodes, props.target), props.children);
              }
              break;
          }
        });
      });
      observer.observe(el.current, {
        childList: true,
        attributes: false,
        subtree: true
      });
    }

    if (props.initial) reset();
    requestAnimationFrame(() => {
      initialRender.current = false;
    });

    if (observer) {
      return () => {
        observer.disconnect();
      };
    }
  }, [ el.current, props.children ]);

  // Add/remove
  useEffect(() => {
    const last = simplify(children);
    const next = simplify(props.children);

    if (next.length > last.length) {
      setChildren(props.children);
    } 
    
    else if (next.length < last.length) {
      if (!el.current) return;

      const elsToRemove = last.reduce((els, component) => {
        const found = next.find((c) => c.key === component.key);
        if (!found) els.push(el.current.querySelector(`[data-transition-key='${component.key.replace('.$', '')}']`));
        return els;
      }, []);
      hideRemoved(elsToRemove, props.children);
    }
  }, [ props.children ])

  // Update keys
  useEffect(() => {
    if (el.current) updateKeys();
  }, [ children ])

  // State
  useEffect(() => {
    if (initialRender.current) {
      if (!el.current) return;
      if (!props.visible) {
        el.current.style.visibility = 'hidden';
      } else {
        el.current.style.visibility = 'visible';
      }
      return;
    }
    if (props.visible) {
      if (visible) {
        show();
      } else {
        setVisible(true);
      }
    } else {
      hide();
    }
  }, [ props.visible ]);

  // Template
  return (
    <>
      {
        If(visible, () => (
          <div ref={el} className={className} style={style}>
            {children}
          </div>
        ))
        .EndIf()
      }
    </>
  );
});
