import { createComponent } from '@lib/util/templateHelpers';
import {
  FIELD_NUMBER,
  FIELD_BOOLEAN,
  FIELD_LIST,
  FIELD_ANY,
  FIELD_STRING,
  FIELD_PASSWORD,
  FIELD_PASSWORD_VERIFY,
  FIELD_EMAIL
} from '@lib/util/validateForm';

const Form = createComponent('Form', {}, function Form ({ className, style }, props) {
  const submit = (evt) => {
    evt.preventDefault();
    if (props.onSubmit) props.onSubmit();
  };

  return (
    <form className={className} style={style} onSubmit={submit} noValidate>
      {props.children}
    </form>
  );
});
export default Form;

Form.FIELD_NUMBER = FIELD_NUMBER;
Form.FIELD_BOOLEAN = FIELD_BOOLEAN;
Form.FIELD_LIST = FIELD_LIST;
Form.FIELD_ANY = FIELD_ANY;
Form.FIELD_STRING = FIELD_STRING;
Form.FIELD_PASSWORD = FIELD_PASSWORD;
Form.FIELD_PASSWORD_VERIFY = FIELD_PASSWORD_VERIFY;
Form.FIELD_EMAIL = FIELD_EMAIL;
