import { createComponent } from '@lib/util/templateHelpers';

const titleStates = [
  'size1',
  'size2',
  'size3',
  'size4',
  'size5',
  'size6',
  'size7',
  'spaced'
];

export default createComponent('Title', { classStates: titleStates }, function Title ({ className, style }, props) {
  let Tag = 'h1';
  if (props.div) Tag = 'h1';
  if (props.h1) Tag = 'h1';
  if (props.h2) Tag = 'h2';
  if (props.h3) Tag = 'h3';
  if (props.h4) Tag = 'h4';
  if (props.h5) Tag = 'h5';
  if (props.h6) Tag = 'h6';

  return (
    <Tag className={className} style={style}>
      {props.children}
    </Tag>
  );
});
