import { Slot, If, For, createComponent, toClassName } from '@lib/util/templateHelpers';
import useInput from '@lib/hooks/input';
import Field from './Field';
import Control from './Control';

const selectStates = [
  'rounded'
];

const Select = createComponent('Select', { classStates: selectStates }, function Select ({ mergeClassNames, style }, props) {
  const {
    states, 
    focus,
    blur, 
    change
  } = useInput(props);

  const className = mergeClassNames(toClassName('Select', states, props));

  return (
    <div className={className} style={style}>
      <select
        style={style}
        value={props.value}
        readOnly={props.readOnly}
        disabled={props.disabled}
        onFocus={focus}
        onBlur={blur}
        onChange={change}
      >
        {props.children}
      </select>
    </div>
  );
});
export default Select;

Select.Field = createComponent('SelectField', {}, function SelectField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      <Field.Label>{slots.label}</Field.Label>
      <Control>
        <Select
          key={key}
          value={form.data[key]} 
          error={!!form.errors[key]} 
          readOnly={props.readOnly}
          disabled={props.disabled}
          onChange={(value) => form.setField(key, value)}
          onFocus={() => form.resetErrors(true)}
          onBlur={(value) => form.validateField(key, value)}
        >
          {slots.defaultSlot}
        </Select>
        {
          If(form.errors[key], () => (
            <Field.Help error>{form.errors[key]}</Field.Help>
          ))
          .ElseIf(slots.help, () => (
            <Field.Help>{slots.help}</Field.Help>
          ))
          .EndIf()
        }
      </Control>
    </Field>
  )
});

Select.Field.List = createComponent('SelectFieldList', {}, function SelectFieldList ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  return (
    <Select.Field name={key} form={form} className={className} style={style}>
      <Slot name='label'>{slots.label}</Slot>
      <option value={''} disabled={true}>&mdash; {props.emptyLabel} &mdash;</option>
      {
        For(schema.acceptedValues, (item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))
      }
    </Select.Field>
  )
});
