import Link from 'next/link';

import { createComponent } from '@lib/util/templateHelpers';

const buttonStates = [
  'small',
  'medium',
  'large',
  'fullWidth',
  'hovered',
  'focused',
  'active',
  'primary',
  'secondary',
  'tertiary',
  'success',
  'warning',
  'error',
  'light',
  'dark',
  'text',
  'static',
  'loading',
  'rounded'
];

const Button = createComponent('Button', { classStates: buttonStates }, function Button ({ className, style }, props) {
  const type = props.submit
    ? 'submit'
    : 'button';

  return (
    <button type={type} className={className} style={style} onClick={props.onClick} disabled={props.disabled} aria-label={props.ariaLabel}>
      {props.children}
    </button>
  );
});
export default Button;

Button.Link = createComponent('Button', { classStates: buttonStates }, function ButtonLink ({ className, style }, props) {
  const type = props.submit
    ? 'submit'
    : 'button';

  return (
    <Link href={props.href}>
      <a type={type} className={className} style={style} onClick={props.onClick} disabled={props.disabled} aria-label={props.ariaLabel}>
        {props.children}
      </a>
    </Link>
  );
});

const buttonsStates = [
  'small',
  'medium',
  'large',
  'addons',
  'centered',
  'right'
];

Button.Buttons = createComponent('Buttons', { classStates: buttonsStates }, function Buttons ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
