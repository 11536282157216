import Cookies from 'js-cookie';

const identifier = process?.env?.IDENTIFIER || 'react-starter-app';

function setStorage (storage, key, value) {
  try {
    storage.setItem(`${identifier}.storage.${key}`, JSON.stringify(value));
    return true;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Error serializing value', error);
    }
    return { error };
  }
}

function getStorage (storage, key) {
  try {
    const cached = storage.getItem(`${identifier}.storage.${key}`);
    return JSON.parse(cached);
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Error serializing value', error);
    }
    return { error };
  }
}

export default {
  setLocal (key, value) {
    return setStorage(window.localStorage, key, value);
  },
  
  getLocal (key) {
    return getStorage(window.localStorage, key);
  },
  
  removeLocal (key) {
    return localStorage.removeItem(key);
  },
  
  clearLocal () {
    return localStorage.clear();
  },
  
  setSession (key, value) {
    return setStorage(window.sessionStorage, key, value)
  },
  
  getSession (key) {
    return getStorage(window.sessionStorage, key)
  },
  
  removeSession (key) {
    return sessionStorage.removeItem(key);
  },
  
  clearSession () {
    return sessionStorage.clear();
  },
  
  setCookie (key, value, options) {
    try {
      Cookies.set(`${identifier}.storage.${key}`, value, options);
      return true;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('Error serializing value', error);
      }
      return { error };
    }
  },
  
  getCookie (key) {
    try {
      return Cookies.getJSON(`${identifier}.storage.${key}`);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('Error serializing value', error);
      }
      return { error };
    }
  },
  
  removeCookie (key, options) {
    Cookies.remove(key, options);
  }
}
