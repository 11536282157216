import { createComponent, toClassName } from '@lib/util/templateHelpers';
import { useTrackComscorePageview } from '../../util/trackComscorePageview';
import PageLoader from './PageLoader';

export default createComponent('Page', {}, function Page ({ mergeClassNames, style }, props) {
  const theme = props.theme || 'default';
  const className = mergeClassNames(toClassName('Theme', { [theme]: true }));

  useTrackComscorePageview()

  return (
    <div className={className} style={style}>
      <PageLoader />
      {props.children}
    </div>
  );
});
