import { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import { createComponent, toClassName } from '@lib/util/templateHelpers';
import useClickOutside from '@lib/hooks/clickOutside';

const dropdownStates = [
  'up',
  'right'
];

const Dropdown = createComponent('Dropdown', { classStates: dropdownStates }, function Dropdown ({ mergeClassNames, style, slots }) {
  const [ menuState, setMenuState ] = useState(false);
  const className = mergeClassNames(toClassName('Dropdown', { active: menuState }));

  // Close when click outside
  const dropdownRef = useRef(null);
  useClickOutside({
    ref: dropdownRef,
    excludeRefs: [
      { matches: 'a.DropdownItem' }
    ]
  }, () => {
    setMenuState(false);
  });

  // Close when clicking a DropdownItem with the 'a' tag
  const handleClick = (evt) => {
    const closest = evt.target.matches('a.DropdownItem')
      ? evt.target
      : evt.target.closest('a.DropdownItem');
    if (closest) setMenuState(false);
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
 
  return (
    <div ref={dropdownRef} className={className} style={style}>
      <div className='Dropdown__Trigger' onClick={() => setMenuState(!menuState)}>
        {slots.trigger}
      </div>
      <div className='Dropdown__Menu' role='menu'>
        <div className='Dropdown__Content'>
          {slots.content}
        </div>
      </div>
    </div>
  );
});
export default Dropdown;

const dropdownItemStates = [
  'active'
];

Dropdown.Item = createComponent('DropdownItem', { classStates: dropdownItemStates }, function DropdownItem ({ className, style }, props) {
  const Tag = props.tag || 'a';
 
  return (
    <Tag className={className} style={style} onClick={props.onClick}>
      {props.children}
    </Tag>
  );
});

Dropdown.Link = createComponent('DropdownItem', { classStates: dropdownItemStates }, function DropdownItem ({ className, style }, props) {
  return (
    <Link href={props.href}>
      <a className={className} style={style}>{props.children}</a>
    </Link>
  );
});

Dropdown.Divider = createComponent('DropdownDivider', {}, function DropdownDivider ({ className, style }, props) {
  return (
    <div className={className} style={style} />
  );
});
