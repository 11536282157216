import { createComponent } from '@lib/util/templateHelpers';

const controlStates = [
  'iconsLeft',
  'iconsRight',
  'loading'
];

export default createComponent('Control', { classStates: controlStates }, function Control ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
