import { If, createComponent } from '@lib/util/templateHelpers';
import { instantIn, instantOut } from '@lib/transitions/instant';
import useModal from '@lib/hooks/modal';

import Delete from './Delete';
import Transition from './Transition';

const Modal = createComponent('Modal', {}, function Modal ({ className, style }, props) {
  const { state, currentModal, Teleporter } = useModal();

  const enterFrom = props.enterFrom || state.enterFrom;
  const enter = props.enter || state.enter || instantIn();
  const leave = props.leave || state.leave || instantOut();

  return (
    <>
    {
      If(Teleporter, () => (
        <Teleporter.Source key={`modal_${props.name}`} multiple>
          <Transition visible={currentModal && currentModal.name === props.name} enterFrom={enterFrom} enter={enter} leave={leave}>
            <div className={className} style={style}>
              {props.children}
            </div>
          </Transition>
        </Teleporter.Source>
      ))
      .EndIf()
    }
    </>
  );
});
export default Modal;

Modal.Modals = createComponent('Modals', {}, function Modals ({ className, style }, props) {
  const { Provider, Teleporter, closeModal, currentModal, setTransitions } = useModal();

  const enter = props.bgEnter || instantIn();
  const leave = props.bgLeave || instantOut();

  if (props.modalEnter && props.modalLeave) {
    setTransitions(props.modalEnter, props.modalLeave);
  }

  return (
    <div className={className} style={style}>
      <Provider>
        <Transition visible={currentModal} enter={enter} leave={leave}>
          <div className='Modals__Bg' onClick={closeModal} />
        </Transition>
        <div className='Modals__Content'>
        {
          If(Teleporter, () => (
            <Teleporter.Target />
          ))
          .Else(() => (
            <div />
          ))
          .EndIf()
        }
        </div>
        {
          If(currentModal && !currentModal.options.hideClose, () => (
            <Delete large onClick={closeModal} />
          ))
          .EndIf()
        }
      </Provider>
    </div>
  );
});

Modal.Card = createComponent('ModalCard', {}, function ModalCard ({ className, style, slots }, props) {
  const { closeModal } = useModal();

  return (
    <div className={className} style={style}>
      {
        If(slots.title, () => (
          <header className='ModalCard__Head'>
            <p className='ModalCard__Title'>{slots.title}</p>
            <Delete onClick={closeModal} />
          </header>
        ))
        .EndIf()
      }
      <section className='ModalCard__Body'>
        {slots.defaultSlot}
      </section>
      {
        If(slots.footer, () => (
          <footer className='ModalCard__Foot'>
            {slots.footer}
          </footer>
        ))
        .EndIf()
      }
    </div>
  );
});
