import { createNetworkApi } from '@lib/util/networkRequest';

import apiInstance from './instances/drupal';

export default createNetworkApi (apiInstance, {
  list: () => ({
    method: 'get',
    url: `/jsonapi/user/user`,
    transform: (data) => data
  }),

  getByUid: (uid) => ({
    method: 'get',
    url: `/jsonapi/user/user?filter[uid][value]=${uid}`,
    transform: (data) => data
  }),

  update: (uuid, user) => ({
    method: 'patch',
    url: `/jsonapi/user/user/${uuid}`,
    data: user,
    transform: (data) => data
  }),

  updateInfo: (uuid, opts) => {
    const data = {};

    if (opts.email) data.mail = [{ value: opts.email }];
    if (opts.about) data.field_about_this_developer = [{ value: opts.about }];
    if (opts.password) data.pass = [{ existing: opts.password }];
    if (opts.oldPassword) data.pass = [{ existing: opts.oldPassword }, { value: opts.passwordAgain }];

    return {
      method: 'patch',
      headers: {
        'Content-Type': 'application/json'
      },
      url: `/user/${uuid}`,
      params: {
        _format: 'json'
      },
      data,
      transform: (data) => data
    }
  },

  register: (name, mail, pass) => {
    return {
      method: 'post',
      url: '/user/registerpass',
      params: {
        _format: 'json'
      },
      data: {
        name: [{ value: name }],
        mail: [{ value: mail }],
        pass: [{ value: pass }]
      },
      transform: (data) => data
    }
  },
});
