import { createComponent } from '@lib/util/templateHelpers';

const spacerStates = [
  'wide',
  'tall'
];

const Spacer = createComponent('Spacer', { classStates: spacerStates }, function Spacer ({ className, style }, props) {
  const [ width, height, src ] = props.spacer;

  return (
    <img className={className} style={style} src={src} aria-hidden={true} width={width} height={height} />
  );
});
export default Spacer;

Spacer.ASPECT_1_1 = [ 1, 1, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC' ];
Spacer.ASPECT_4_3 = [ 4, 3, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBlcCBAAAAAICg/KlnOLYAADMAAcyCH7AAAAAASUVORK5CYII=' ];
Spacer.ASPECT_2_1 = [ 2, 1, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAYAAAD0In+KAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAALSURBVHgBY2CAAgAACQABzwe78AAAAABJRU5ErkJggg==' ];
Spacer.ASPECT_37_20 = [ 37, 20, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAUCAYAAAAKuPQLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAaSURBVHgB7cAxAQAAAMKg9U9tDQ8oAADgyQALpAABkoFzuQAAAABJRU5ErkJggg==' ];
Spacer.ASPECT_16_9 = [ 16, 9, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAYAAAA7KqwyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAXSURBVHgB7cAxAQAAAEAw/VMr4TTYAgICSQABhN4O5AAAAABJRU5ErkJggg==' ];
Spacer.ASPECT_16_10 = [ 16, 10, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAKCAYAAAC9vt6cAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAXSURBVHgB7cAxAQAAAEAw/VOr4TDYCgQCigABFmtWywAAAABJRU5ErkJggg==' ];
Spacer.ASPECT_21_9 = [ 21, 9, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAJCAYAAADdA2d2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAWSURBVHgB7cABAQAAAEAg/1cbQrBFCQL9AAEJ+XC8AAAAAElFTkSuQmCC' ];
Spacer.ASPECT_9_16 = [ 9, 16, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAXSURBVHgB7cAxAQAAAEAw/VMr4TTYOgICUAABfwAT2wAAAABJRU5ErkJggg==' ];
Spacer.ASPECT_6_5 = [ 6, 5, 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAFCAYAAABmWJ3mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAVSURBVHgBxcCBAAAAAICg/KnHOEYBAH0AAWpzv30AAAAASUVORK5CYII=' ];
