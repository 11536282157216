import Image from 'next/image';
import Link from 'next/link';
import { useRef, useEffect } from 'react';

import { For, createComponent } from '@lib/util/templateHelpers';
import Content from './Content';
import Spacer from './Spacer';

const gameTileStates = [
  'medium',
  'large',
  'fullWidth',
  'stretch',
  'disabled'
];

const GameTile = createComponent('GameTile', { classStates: gameTileStates }, function GameTile ({ className, style, slots }, props) {
  const href = props.href
    ? (props.disabled ? '#' : props.href)
    : '#';

  const gameTileRef = useRef(null);
  const playPromiseRef = useRef(undefined);

  const playVideo = () => {
    const videoEl = gameTileRef.current.querySelector('video');
    if (!videoEl) return;

    videoEl.muted = true;
    videoEl.currentTime = 0;
    playPromiseRef.current = videoEl.play();
  };

  const pauseVideo = () => {
    const videoEl = gameTileRef.current.querySelector('video');
    if (!videoEl) return;

    if (playPromiseRef.current !== undefined) {
      playPromiseRef.current.then(() => {
        videoEl.pause();
        videoEl.load();
      });
    }
  };

  const mouseEnter = () => {
    if (props.onMouseEnter) props.onMouseEnter();
  };

  const mouseLeave = () => {
    if (props.onMouseLeave) props.onMouseLeave();
  };

  useEffect(() => {
    if (props.innerRef) props.innerRef.current = gameTileRef.current;
  }, [props.innerRef]);

  return (
    <Link 
      href={href} 
      passHref={true} 
      onClick={props.onClick}
      prefetch={props.prefetch}
    >
      <a 
        className={className}
        style={style}
        ref={gameTileRef}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        data-id={props.id}
      >
        <Spacer wide spacer={props.spacer || Spacer.ASPECT_16_10} />
        <div className='GameTile__Preview'>{slots.preview}</div>
        <div className='GameTile__Description'>{slots.description}</div>
        <div className='GameTile__Status'>{slots.status}</div>
      </a>
    </Link>
  );
});
export default GameTile;

GameTile.Thumbnail = createComponent('GameTileThumbnail', {}, function GameTileThumbnail ({ className }, props) {
  return (
    <Image
      className={className}
      src={props.src}
      alt={props.alt}
      layout='fill'      
      objectFit='cover'
      priority={props.priority}
    />
  );
});

GameTile.VideoThumbnail = createComponent('GameTileVideoThumbnail', {}, function GameTileThumbnail ({ className, style }, props) {
  const ext = (src) => {
    const parts = src.split('.');
    return parts[parts.length - 1];
  };

  return (
    <div className={className} style={style}>
      <Image
        className='GameTileVideoThumbnail__Poster'
        src={props.poster}
        alt={props.alt}
        layout='fill'      
        objectFit='cover'
        priority={props.priority}
      />
      <video 
        className='GameTileVideoThumbnail__Video'
        loop 
        muted={true}
        playsInline
        preload='none'
      >
        {
          For(props.src, (src) => (
            <source key={src} src={src} type={`video/${ext(src)}`} />
          ))
        }
      </video>
    </div>
  );
});

GameTile.Description = createComponent('GameTileDescription', {}, function GameTileDescription ({ className, style }, props) {
  return (
    <Content className={className} style={style}>
      {props.children}
    </Content>
  );
});

GameTile.Status = createComponent('GameTileStatus', {}, function GameTileStatus ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
