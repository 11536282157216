import { createComponent } from '@lib/util/templateHelpers';

const deleteStates = [
  'large'
];

export default createComponent('Delete', { classStates: deleteStates }, function Delete ({ className, style }, props) {
  return (
    <div className={className} style={style} onClick={props.onClick}>
      {props.children}
    </div>
  );
});
