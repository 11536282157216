export function instantOut () {
  return {
    opacity: 0,
    easing: 'linear',
    duration: 0
  }
}

export function instantIn () {
  return {
    opacity: 1,
    easing: 'linear',
    duration: 0
  }
}
