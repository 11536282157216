import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function trackComscorePageview () {
  if (typeof window === undefined) return;

  if (self && self.COMSCORE) {
    self.COMSCORE.beacon({ c1: '2', c2: process.env.APP_CONFIG.COMSCORE_CLIENT_ID });

    fetch('/api/comscore');
  }
}

export function useTrackComscorePageview () {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', trackComscorePageview);
    return () => {
      router.events.off('routeChangeComplete', trackComscorePageview);
    };    
    
  }, [ router.events ]);

  useEffect(trackComscorePageview, []);
}
