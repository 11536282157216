import { useRef, useEffect } from 'react';

export default function useEffectOnce (callback, reset, props) {
  const useEffectRef = useRef(false);

  if (!props) {
    props = reset
    reset = null
  }

  useEffect(() => {
    let offEffect;

    if (!useEffectRef.current) {
      const result = callback();
      if (typeof result === 'function') {
        offEffect = result;
        useEffectRef.current = true;
      } else {
        useEffectRef.current = result;
      }
    }

    if (reset && reset()) useEffectRef.current = false;

    if (offEffect) return offEffect;
  }, props);
}
