import { If, For, createComponent } from '@lib/util/templateHelpers';
import Field from './Field';
import Control from './Control';

const radioStates = [
  'error',
  'small',
  'medium',
  'large'
];

const Radio = createComponent('Radio', { classStates: radioStates }, function Radio ({ className, style }, props) {
  const change = (evt) => {
    const value = evt.target.value === 'on'
      ? props.value
      : false;
    if (props.onChange) props.onChange(value);
  };

  return (
    <label 
      className={className} 
      style={style}
      readOnly={props.readOnly}
      disabled={props.disabled}
    >
      <input
        type='radio'
        name={props.name}
        readOnly={props.readOnly}
        disabled={props.disabled}
        defaultChecked={props.defaultChecked}
        onChange={change}
      />
      <span />
      <span>{props.children}</span>
    </label>
  );
});
export default Radio;

Radio.Field = createComponent('RadioField', {}, function RadioField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;
  const schema = form.schema[key].schema;

  const change = (value) => {
    form.validateField(key, value);
  };

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      <Field.Label>{slots.label}</Field.Label>
        {
          For(schema.acceptedValues, (item) => (
            <Control key={`${key}_${item.value}`}>
              <Radio
                name={key}
                value={item.value}
                error={!!form.errors[key]}
                readOnly={props.readOnly}
                disabled={props.disabled}
                defaultChecked={form.data[key] === item.value}
                onChange={change}
              >
                {item.label}
              </Radio>
            </Control>
          ))
        }
        <Control>
          {
            If(form.errors[key], () => (
              <Field.Help error>{form.errors[key]}</Field.Help>
            ))
            .ElseIf(slots.help, () => (
              <Field.Help>{slots.help}</Field.Help>
            ))
            .EndIf()
          }
        </Control>
    </Field>
  )
});
