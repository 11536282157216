import axios from 'axios';

export function createNetworkApi (instanceOrURL, methods, options = {}) {
  let instance
  if (typeof instanceOrURL === 'string') {
    instance = axios.create({
      baseURL: instanceOrURL,
      ...options,
    });
  } else {
    instance = instanceOrURL;
    for (const key in options) {
      instance.defaults[key] = options[key];
    }
  }

  const request = (options) => {
    return new Promise((resolve) => {
      const resolver = (response) => {
        if (options.transform) response = options.transform(response);
        resolve(response);
      };
      instance.request(options)
        .then(resolver)
        .catch((error) => {
          if (process.env.NODE_ENV === 'development') console.error(error.data);
          resolve({ error })
        });
    });
  };

  const api = {};

  for (const key in methods) {
    api[key] = function () {
      const options = methods[key].apply(null, Array.from(arguments));
      return request(options);
    };
  }

  return api;
}

function getPairs (obj, keys = []) {
  return Object.entries(obj).reduce((pairs, [key, value]) => {
    if (Array.isArray(value)) pairs.push([[...keys, key], value.join(',')]);
    else if (typeof value === 'object') pairs.push(...getPairs(value, [...keys, key]));
    else pairs.push([[...keys, key], value]);

    return pairs;
  }, []);
}

export function toQuery (url, params) {
  const query = getPairs(params)
    .map(([[key0, ...keysRest], value]) => `${key0}${keysRest.map(a => `[${a}]`).join('')}=${value}`)
    .join('&');

  return url + '?' + query;
}
