import rating from '../util/rating';

function LDJson ({ data }) {
  return (
    <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(data, null, 2) }} />
  );
}
export default LDJson;

LDJson.WebSite = function LDJsonWebSite ({ path }) {
  const formattedData = {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    'name': process.env.APP_CONFIG.DEFAULT_TITLE,
    'url': process.env.APP_CONFIG.URL + path
  };

  if (process.env.APP_CONFIG.SEARCH_URL) {
    formattedData.potentialAction = {
      '@type': 'SearchAction',
      'target': `${process.env.APP_CONFIG.SEARCH_URL}{search_term_string}`,
      'query-input': 'required name=search_term_string'
    }
  }

  return (
    <LDJson data={formattedData} />
  );
};

LDJson.ContactPage = function LDJsonContactPage ({ path }) {
  const formattedData = {
    '@context': 'https://schema.org/',
    '@type': 'ContactPage',
    'name': process.env.APP_CONFIG.DEFAULT_TITLE,
    'url': process.env.APP_CONFIG.URL + path
  };

  return (
    <LDJson data={formattedData} />
  );
};

LDJson.Organization = function LDJsonOrganization () {
  const formattedData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': process.env.APP_CONFIG.ORGANIZATION.NAME,
    'url': process.env.APP_CONFIG.ORGANIZATION.URL,
    'logo': process.env.APP_CONFIG.ORGANIZATION.LOGO,
    'sameAs': [
      ...(process.env.APP_CONFIG.ORGANIZATION.SOCIAL_LINKS || [])
    ]
  };

  return (
    <LDJson data={formattedData} />
  );
}

export function gameToLdJson (game) {
  return {
    '@context': 'https://schema.org',
    '@type': 'VideoGame',
    'name': game.title,
    'url': game.path,
    'image': game.thumbnailUrl,
    'description': game.descriptionSimple,
    'inLanguage': [ 'English' ],
    'aggregateRating':{
      '@type': 'AggregateRating',
      'ratingValue': rating(game.rating),
      'ratingCount': game.totalVotes
    },
    // 'author':{
    //   '@type': 'Organization',
    //   'name': '',
    //   'url': ''
    // },
    'genre': game.categories.map((category) => category.name),
    'gamePlatform': [ 'HTML5' ]
  }
}

LDJson.Game = function LDJsonGame ({ data }) {
  const formattedData = gameToLdJson(data);

  return (
    <LDJson data={formattedData} />
  );
}

LDJson.GameList = function LDJsonGameList ({ data }) {
  const formattedData = {
    '@type': 'ItemList',
    'itemListElement': data.map((game, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'url': game.path,
      'item': gameToLdJson(game)
    }))
  };

  return (
    <LDJson data={formattedData} />
  );
};

LDJson.BreadcrumbList = function LDJsonBreadcrumbList ({ data }) {
  const formattedData = {
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        '@type': 'ListItem',
        'position': 1,
        'item': {
          '@id': process.env.APP_CONFIG.URL,
          'name': process.env.APP_CONFIG.DEFAULT_TITLE
        }
      },
      ...data.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        'position': index + 2,
        'item': {
          '@id': breadcrumb.url,
          'name': breadcrumb.name
        }
      }))
    ]
  };

  return (
    <LDJson data={formattedData} />
  );
};

LDJson.FAQList = function LDJsonFAQList ({ data }) {
  const formattedData = {
    '@context': 'http://schema.org/',
    '@type': 'FAQPage',
    'mainEntity': data.map((faq) => ({
      '@type': 'Question',
      'name': faq.question,
      'acceptedAnswer': {
        '@type': 'Answer',
        'text': faq.answer
      }
    }))
  };

  return (
    <LDJson data={formattedData} />
  );
};
