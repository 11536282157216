import { useEffect, useState, useRef } from 'react';

import { If, toClassName, createComponent } from '@lib/util/templateHelpers';
import Flex from './Flex';
import Spacer from './Spacer';
import Transition from './Transition';
import Delete from './Delete';
import { isMobileBreakpoint, isMobileDevice } from '../util/mobile';

const STATE_WAITING = 'waiting';
const STATE_INTERSTITIAL = 'interstitial';
const STATE_LOADING = 'loading';
const STATE_HIDDEN = 'hidden';
const STATE_ERROR = 'error';

async function verifyResource (url) {
  try {
    const result = await fetch(url, {
      method: 'HEAD'
    });
    return result.status !== 404;
  } catch {
    return false;
  }
}

const gamePlayerStates = [
  'fluid'
];

export default createComponent('GamePlayer', { classStates: gamePlayerStates }, function GamePlayer ({ className, mergeClassNames, style, slots }, props) {
  // Component state
  const [ showGame, setShowGame ] = useState(false);
  const [ previewState, setPreviewState ] = useState(STATE_WAITING);
  const objectEl = useRef(null);

  const [ fullScreen, setFullScreen ] = useState(props.fullScreen);

  // Preview/interstial logic
  const previewStyles = {
    backgroundImage: `url(${props.previewImage})`
  };

  // Watch preview state
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (previewState === STATE_INTERSTITIAL) {
      if (props.onClickPlay) {
        props.onClickPlay(() => {
          loadGame();
        });
      }
    }
  }, [ previewState ]);

  // Load game
  const loadGame = () => {
    setPreviewState(STATE_LOADING);
    setShowGame(true);
  };

  useEffect(() => {
    if (!objectEl.current) return;
    
    (async () => {
      const el = objectEl.current;
      if (el.hasAttribute('data')) return;
      el.setAttribute('data', props.gameSrc);
    })();
  }, [ showGame ])

  useEffect(() => {
    if (!props.preview) {
      loadGame();
    }
  }, []);

  // Handlers & methods
  const clickPlay = () => {
    // If the user is on mobile then try to fullScreen the game
    if (isMobileBreakpoint()) {
      setFullScreen(true);
    }

    if (props.onClickPlay) {
      setPreviewState(STATE_INTERSTITIAL);
    } else {
      setShowGame(true);
    }
  };

  const gameLoaded = (evt) => {
    setPreviewState(STATE_HIDDEN);
  };

  // Fullscreen logic
  const boundingBox = {
    top: props.boundingBox?.top || 0,
    left: props.boundingBox?.left || 0,
    right: props.boundingBox?.right || 0,
    bottom: props.boundingBox?.bottom || 0
  };

  const clickExitFullScreen = () => {
    if (props.onClickExitFullScreen) {
      props.onClickExitFullScreen();
    }
  };

  // Handler for the parent to request a change to the fullScreen state
  useEffect(() => {
    setFullScreen(props.fullScreen);
  }, [ props.fullScreen ]);

  // Handler for built-in browser fullScreen API events
  useEffect(() => {
    const handler = () => {
      const fullScreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.webkitCurrentFullScreenElement ||
        document.msFullscreenElement;

      setFullScreen(!!fullScreenElement);
    };

    document.documentElement.addEventListener('fullscreenchange', handler);
    document.documentElement.addEventListener('webkitfullscreenchange', handler);

    return () => {
      document.documentElement.removeEventListener('fullscreenchange', handler);
      document.documentElement.removeEventListener('webkitfullscreenchange', handler);
    }
  }, []);

  // Handler for fullScreen state
  useEffect(() => {

    if (fullScreen) {
      document.body.classList.add('--fullScreen');
    } else {
      clickExitFullScreen();
      document.body.classList.remove('--fullScreen');
    }

    // Bail out if either:
    // 1. This is a mobile device since many don't support the fullScreen API
    // 2. The game object element doesn't exist yet since it needs to be ready
    //    in order to use the fullScreen API
    if (isMobileDevice() || !objectEl.current) return;

    // Otherwise, invoke the browser's fullScreen API
    const fullScreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.webkitCurrentFullScreenElement ||
      document.msFullscreenElement;

    if (fullScreen && !fullScreenElement) {
      const requestFullscreen =
        document.documentElement.requestFullscreen ||
        document.documentElement.webkitRequestFullScreen ||
        document.documentElement.webkitRequestFullscreen ||
        document.documentElement.mozRequestFullScreen ||
        document.documentElement.msRequestFullscreen;

      requestFullscreen.call(document.documentElement, { navigationUI: 'hide' });
    }

    if (!fullScreen && fullScreenElement) {
      const exitFullscreen =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;

      exitFullscreen.call(document);
    }

  }, [ fullScreen ]);

  // Game object
  const gameStyles = {
    width: props.width,
    height: props.height
  };

  // Styles
  style = {
    ...style,
    ...(fullScreen ? boundingBox : {})
  };

  // Control fullScreen class internally
  className = mergeClassNames(toClassName('GamePlayer', { fullScreen }), className);

  return (
    <div className={className} style={style}>
      <Transition 
        visible={previewState !== STATE_HIDDEN} 
        leave={props.previewTransition} 
        className='GamePlayer__Overlay'
      >
        <div className='GamePlayer__PreviewOverlay' style={previewStyles} />
        {
          If(previewState === STATE_WAITING, () => (
            <Flex alignCenter justifyCenter className='GamePlayer__Overlay'>
              <div onClick={clickPlay}>
                {slots.playButton}
              </div>
            </Flex>
          ))
          .ElseIf(previewState === STATE_INTERSTITIAL, () => (
            <Flex alignCenter justifyCenter className='GamePlayer__Overlay'>
              {slots.interstitial}
            </Flex>
          ))
          .ElseIf(previewState === STATE_LOADING, () => (
            <Flex alignCenter justifyCenter className='GamePlayer__Overlay'>
              <div className='GamePlayer__Loader' />
            </Flex>
          ))
          .ElseIf(previewState === STATE_ERROR, () => (
            <Flex alignCenter justifyCenter className='GamePlayer__Incompatible'>
              {slots.error ? slots.error : 'An error occurred while loading this game. Please try again later.'}
            </Flex>
          ))
          .EndIf()
        }
        <Transition 
          visible={previewState !== STATE_HIDDEN && previewState !== STATE_ERROR} 
          leave={{ translateY: '150%', opacity: 0 }} 
          className={toClassName('GamePlayer__Anchor', '&--bottom')} 
          style={{ width: '100%' }}
        >
          <Flex justifyCenter wide>
            <div>
              {slots.bottomPromo}
            </div>
          </Flex>
        </Transition>
      </Transition>
      <div className='GamePlayer__Game' style={gameStyles}>
        {
          If(showGame || !props.preview, () => (
            <object 
              ref={objectEl} 
              width={props.width} 
              height={props.height}
              onLoad={gameLoaded}
            />
          ))
          .EndIf()
        }
        {
          If(props.spacer, () => (
            <Spacer wide spacer={props.spacer} />
          ))
          .EndIf()
        }
      </div>
      {
        If(!props.supportsMobile, () => (
          <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--mobile')}>
            {slots.mobileUnsupported ? slots.mobileUnsupported : 'This game does not support mobile devices.'}
          </Flex>
        ))
        .Else(() => (
          <>
            {
              If(!props.supportsMobile.landscape, () => (
                <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--landscape')}>
                  {slots.landscapeUnsupported ? slots.landscapeUnsupported : 'Rotate the device into portrait mode to play this game.'}
                </Flex>
              ))
              .EndIf()
            }
            {
              If(!props.supportsMobile.portrait, () => (
                <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--portrait')}>
                  {slots.portraitUnsupported ? slots.portraitUnsupported : 'Rotate the device into landscape mode to play this game.'}
                </Flex>
              ))
              .EndIf()
            }
          </>
        ))
        .EndIf()
      }
      {
        If(fullScreen, () => (
          <div className={toClassName('GamePlayer__Anchor', '&--top', '&--right')} style={{zIndex: 4}}>
            <Flex 
              alignCenter 
              justifyCenter
            >
              <span className='GamePlayer__CloseButton' onClick={() => setFullScreen(false)}>&times;</span>
            </Flex>
          </div>
        ))
        .EndIf()
      }
    </div>
  );
});
