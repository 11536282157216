import { createComponent } from '@lib/util/templateHelpers';

const flexStates = [
  'inline',
  'wrap',
  'wrapReverse',
  'directionColumn',
  'directionColReverse',
  'directionRow',
  'directionRowReverse',
  'alignStart',
  'alignEnd',
  'alignCenter',
  'alignBaseline',
  'alignStretch',
  'justifyStart',
  'justifyEnd',
  'justifyCenter',
  'justifySpaceBetween',
  'justifySpaceAround',
  'justifySpaceEvenly',
  'pullTop',
  'pullRight',
  'pullBottom',
  'pullLeft',
  'centerX',
  'centerY',
  'center',
  'fit',
  'narrow',
  'wide',
  'tall',
  'fill',
  'threeQuarters',
  'twoThirds',
  'half',
  'oneThird',
  'oneQuarter',
  'oneFifth',
  'twoFifths',
  'threeFifths',
  'fourFifths',
  'gap0',
  'gap1',
  'gap2',
  'gap3',
  'gap4',
  'gap5',
  'gap6',
  'container',
  'collapseMobile'
];

const Flex = createComponent('Flex', { classStates: flexStates }, function Flex ({ className, style }, props) {
  style = {
    ...style,
    order: props.order
  };

  const Tag = props.tag || 'div';

  return (
    <Tag className={className} style={style} onClick={props.onClick}>
      {props.children}
    </Tag>
  );
});
export default Flex;
