import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

export default function RouterLink (props) {
  const router = useRouter();

  let className = [ 'RouterLink' ];
  if (props.className) className.push(props.className);
  if (router.asPath === props.href) className.push('--active');
  className = className.join(' ').trim();

  return (
    <Link style={props.style} href={props.href}>
      <a className={className} onClick={props.onClick}>{props.children}</a>
    </Link>
  );
}
