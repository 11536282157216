import { createComponent } from '@lib/util/templateHelpers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const iconStates = [
  'small',
  'medium',
  'large'
];

const Icon = createComponent('Icon', { classStates: iconStates }, function Icon ({ className, style }, props) {
  return (
    <span className={className} style={style} onClick={props.onClick} aria-label={props.ariaLabel}>
      {props.children}
    </span>
  );
});
export default Icon; 

Icon.WithText = createComponent('IconWithText', {}, function IconWithText ({ className, style }, props) {
  return (
    <span className={className} style={style} onClick={props.onClick}>
      {props.children}
    </span>
  );
});

Icon.FA = createComponent('IconFA', {}, function IconFA ({ className, style }, props) {
  return (
    <Icon {...props} className={className} style={style} ariaLabel={props.ariaLabel}>
      <FontAwesomeIcon icon={props.icon} />
    </Icon>
  );
});
