import { useRef, useEffect } from 'react';
import { For, createComponent } from '@lib/util/templateHelpers';
import { slideUpIn, slideUpOut, slideUpEnterFrom } from '@lib/transitions/slideUp';
import useNotifications from '@lib/hooks/notifications';
import Transition from './Transition';
import Delete from './Delete';

const SUCCESS = 'success';
const WARNING = 'warning';
const ERROR = 'error';

const notificationStates = [
  'warning',
  'success',
  'error'
];

const Notification = createComponent('Notification', { classStates: notificationStates }, function Notification ({ className, style }, props) {
  return (
    <div className={className} style={style} onClick={props.onDismiss}>
      {props.children}
    </div>
  );
});
export default Notification;

const notificationsStates = [
  'topRight',
  'middle'
];

Notification.Notifications = createComponent('Notifications', { classStates: notificationsStates }, function Notifications ({ className, style }, props) {
  const { state, dismiss } = useNotifications();

  const enterFrom = props.enterFrom || slideUpEnterFrom();
  const enter = props.enter || slideUpIn();
  const leave = props.leave || slideUpOut();

  const updateIntervalRef = useRef(null);
  useEffect(() => {
    clearInterval(updateIntervalRef.current);

    const notificationUpdateInterval = 100;
    updateIntervalRef.current = setInterval(() => {
      const notification = state.notifications[0];
      if (!notification) return

      notification.aliveFor -= notificationUpdateInterval;
      if (notification.aliveFor <= 0) {
        const index = state.notifications.indexOf(notification);
        dismiss(index);
      }
    }, notificationUpdateInterval);

    return () => {
      clearInterval(updateIntervalRef.current);
    };
  }, [state]);

  return (
    <div className={className} style={style}>
      <Transition.List visible={true} enterFrom={enterFrom} enter={enter} leave={leave} target='.Notification'>
        {
          For(state.notifications, (item, i) => (
            <Notification 
              key={item.id} 
              success={props.status === 'success'}
              warning={props.status === 'warning'}
              error={props.status === 'error'}
              onDismiss={() => dismiss(i)}
            >
              {item.message}
              <Delete />
            </Notification>
          ))
        }
      </Transition.List>
    </div>
  );
});

Notification.SUCCESS = SUCCESS;
Notification.WARNING = WARNING;
Notification.ERROR = ERROR;
