import { createComponent } from '@lib/util/templateHelpers';

const tileStates = [
  'container',
  'child',
  'parent',
  'vertical',
  'shrink',
  'gap1',
  'gap2',
  'gap3',
  'gap4',
  'gap5',
  'gap6',
  'size1',
  'size2',
  'size3',
  'size4',
  'size5',
  'size6',
  'size7',
  'size8',
  'size9',
  'size10',
  'size11',
  'size12'
];

export default createComponent('Tile', { classStates: tileStates }, function Tile ({ className, style }, props) {
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  );
});
