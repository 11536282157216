import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import useClickOutside from '@lib/hooks/clickOutside';
import useNotifications from '@lib/hooks/notifications';
import { For, If, createComponent, toClassName } from '@lib/util/templateHelpers';

import Control from './Control';
import Field from './Field';
import Icon from './Icon';

const autocompleteStates = [
  'up',
  'right'
];

const Autocomplete = createComponent('Autocomplete', { classStates: autocompleteStates }, function Autocomplete ({ mergeClassNames, style }, props) {
  const [ activeIndex, setActiveIndex ] = useState(-1);
  const [ matches, setMatches ] = useState([ 'game' ]);
  const [ query, setQuery ] = useState('');
  const [ menuState, setMenuState ] = useState(false);
  
  const router = useRouter();
  const { notify } = useNotifications();
  const className = mergeClassNames(toClassName('Autocomplete', { active: menuState }));

  // Close when click outside
  const ref = useRef(null);
  useClickOutside({
    ref,
    excludeRefs: [
      { matches: 'a.DropdownItem' }
    ]
  }, () => {
    setMenuState(false);
    setMatches([]);
  });

  const minChars = 2;

  useEffect(() => { 
    // Without the entire updateQuery function in this useEffect hook
    // The mataches state will lag behind the query state
    // Setting useEffect to trigger on both [query, matches] creates an infinite loop
    const updateQuery = async () => {
      if (query.length < minChars) return;

      const data = await props.searchQuery(encodeURIComponent(query));
      
      if (data.length) {
        setMenuState(true);
        setMatches(data);
      } else {
        setMenuState(false);
        setMatches([]);
      }
    }

    updateQuery();
  }, [query]);

  const Tag = props.tag || 'a';

  const reset = () => {
    setQuery('');
    setMenuState(false);
    setMatches([]);
    setActiveIndex(-1);
  };

  const doSearch = () => {
    if (query.length < minChars) {
      notify(`Search terms must be at least ${minChars} characters in length.`);
      return;
    }

    props.onSearch(query);
    reset();
  };

  const handleKeyPress = (e) => {
    switch (e.which) {
      case 13: // Enter key
        if (matches.length && matches[activeIndex]) {   
          props.onSelect(matches[activeIndex]);
          reset();
        } else {
          doSearch();
        }
        break;
      case 38: // Up arrow
        setActiveIndex(activeIndex >= 1 ? activeIndex - 1 : 0)
        break;
      case 8: // Delete key
        setMenuState(query === '' ? query !== '' && matches.length: true );
        break;
      case 40: // Down arrow
        setActiveIndex(
          activeIndex < matches.length - 1
          ? activeIndex + 1
          : matches.length - 1
        );
        break;
      }
  };

  useEffect(() => {
    const handleRouteChangeStart = () => reset();
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    }
  }, []);

  return (
    <div ref={ref} className={className} style={style}>
      <div className='Autocomplete__Trigger'>
        <Field>
          <Control iconsRight>
            <input
              className='Input'
              type='text' 
              placeholder={props.placeholder}
              value={query}
              onChange={e => setQuery(e.target.value)}
              onKeyDown={handleKeyPress}
              onBlur={props.onBlur}
              onFocus={props.onFocus}
              aria-label='Search iogames'
              name='search'
            />
            <Icon.FA icon={faSearch} onClick={doSearch} ariaLabel='Perform a search' />
          </Control>
        </Field>
      </div>
      <div className='Autocomplete__Menu' role='menu'>
        {
          If(matches.length > 0, () => (
            <div className='Autocomplete__Content'>
              {
                For(matches, (item, index) => (
                  <Tag
                    href={props.findItemPath(item) || '/'} 
                    key={index} 
                    className={toClassName('AutocompleteItem', { active: index === activeIndex })} 
                  >
                    {item.title}
                  </Tag>
                ))
              }
            </div>
          )).EndIf()
        }
      </div>
    </div>
  );
});

export default Autocomplete;
