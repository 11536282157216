export default function useInput (props) {
  const states = [
    'focused',
    'active',
    'hovered',
    'error',
    'static',
    'small',
    'medium',
    'large'
  ];

  const focus = (evt) => {
    if (props.onFocus) props.onFocus(evt.target.value);
  };

  const blur = (evt) => {
    if (props.onBlur) props.onBlur(evt.target.value);
  };

  const change = (evt) => {
    if (props.onChange) props.onChange(evt.target.value);
  };

  const keyPress = (evt) => {
    if (props.onKeyDown) props.onChange(evt.target.value);
    else if (props.onKeyUp) props.onChange(evt.target.value);
    else if (props.onKeyPress) props.onChange(evt.target.value);
  };

  return {
    states,
    focus,
    blur,
    change,
    keyPress
  }
}
