import { useRef } from 'react';
import useEffectOnce from './effectOnce';

export const defaultBreakpoints = {
  phoneSmall: 320,
  phone: 375,
  tablet: 768,
  desktop: 960,
  widescreen: 1152,
  highdef: 1344,
  ultrahd: 1920
};

export function checkBreakpoint (breakpoint, breakpoints = defaultBreakpoints) {
  const parts = breakpoint.split(/([<>=]+)/g);
  parts.shift();
  
  const operator = parts.shift();

  breakpoint = breakpoints[parts.shift()];
  if (!breakpoint) return;

  switch (operator) {
    case '>':
      return window.innerWidth > breakpoint;
    case '>=':
      return window.innerWidth >= breakpoint;
    case '<':
      return window.innerWidth < breakpoint;
    case '<=':
      return window.innerWidth <= breakpoint;
  }
}

export function useResize (callback, dontInit, breakpoints = defaultBreakpoints) {
  const lastWidthRef = useRef(-1);
  const lastHeightRef = useRef(-1);

  const cb = () => {
    const lastWidth = lastWidthRef.current;
    const curWidth = window.innerWidth;
    lastWidthRef.current = curWidth;

    const lastHeight = lastHeightRef.current;
    const curHeight = window.innerHeight;
    lastHeightRef.current = curHeight;
    
    if (lastWidth === curWidth && lastHeight === curHeight) return;
    if (lastWidth === curWidth && lastWidth <= 768) return; // hack for iOS

    callback(
      window.innerWidth, 
      window.innerHeight,
      (breakpoint) => checkBreakpoint(breakpoint, breakpoints)
    );
  };

  let handler, debounce;
  useEffectOnce(() => {
    if (!dontInit) {
      cb();
    }
    
    handler = () => {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        cb();
      }, 400);
    };
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, null, []);
}
