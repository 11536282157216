import Head from 'next/head';
import { useRouter } from 'next/router';

import storage from '@lib/util/storage';
import useModal from '@lib/hooks/modal';
import useNotifications from '@lib/hooks/notifications';
import useEffectOnce from '@lib/hooks/effectOnce';
import shared from '@lib/components';

export default function App (props) {
  const {
    Page,
    Modal,
    Notification,
    DocumentTitle,
    ComScore,
    GoogleTagManager, 
    GoogleTagManagerNoscript
  } = shared;

  const { useAuthStore, verifyAction, readyAction } = props;
  const [ authState, authDispatch ] = useAuthStore();
  const router = useRouter();

  useEffectOnce(
    () => {
      const cached = storage.getLocal('auth');
      if (authState.auth || cached?.auth) {
        if (authState.auth) {
          authDispatch(verifyAction);
          return true;
        }
      } else {
        authDispatch(readyAction);
        return true;
      }
    }, 
    () => {
      if (!authState.auth && !authState.user) {
        return true;
      }
    },
    [authState.auth]
  );

  return (
    <>
      <DocumentTitle />

      <Head>
        {/* Analytics */}
        <ComScore clientId={process.env.APP_CONFIG.COMSCORE_CLIENT_ID} />
        <GoogleTagManager clientId={process.env.APP_CONFIG.GOOGLE_ANALYTICS_ID} />

        {/* Configuration */}
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no' />
        <meta httpEquiv='content-language' content='en' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta httpEquiv='audience' content='General' />
        <meta httpEquiv='Content-Security-Policy' content='upgrade-insecure-requests' />

        {/* Description */}
        <meta name='description' content={process.env.APP_CONFIG.DESCRIPTION} />
        <meta name='title' content={process.env.APP_CONFIG.APP_TITLE} />
        <meta name='author' content={process.env.APP_CONFIG.APP_TITLE} />
        <meta name='keywords' content={process.env.APP_CONFIG.KEYWORDS} />
        <meta name='news_keywords' content={process.env.APP_CONFIG.KEYWORDS} />
        <meta name='distribution' content='Global' />
        <link rel='canonical' href={`${process.env.APP_CONFIG.URL}${router.asPath}`} />
        <link rel='shortlink' href={`${process.env.APP_CONFIG.URL}${router.asPath}`} />

        {/* Favicons */}
        <link href='/images/app/favicon-16.png' rel='icon' type='image/png' sizes='16x16' />
        <link href='/images/app/favicon-32.png' rel='icon' type='image/png' sizes='32x32' />
        <link href='/images/app/favicon-48.png' rel='icon' type='image/png' sizes='48x48' />
        <link href='/images/app/favicon-48.png' rel='mask-icon' size='any' color={process.env.APP_CONFIG.THEME} />

        {/* Orientation  */}
        <meta name='screen-orientation' content='portrait' />

        {/* Android */}
        <meta name='theme-color' content={process.env.APP_CONFIG.THEME} />
        <meta name='mobile-web-app-capable' content='yes' />
        <link href='/images/app/icon-128.png' rel='icon' sizes='128x128' />
        <link href='/images/app/icon-192.png' rel='icon' sizes='192x192' />

        {/* iOS */}
        <meta name='apple-mobile-web-app-title' content={process.env.APP_CONFIG.DEFAULT_TITLE} />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-status-bar-style' content='default' />
        <link href='/images/app/icon-76.png' rel='apple-touch-icon' />
        <link href='/images/app/icon-76.png' rel='apple-touch-icon' sizes='76x76' />
        <link href='/images/app/icon-120.png' rel='apple-touch-icon' sizes='120x120' />
        <link href='/images/app/icon-152.png' rel='apple-touch-icon' sizes='152x152' />
        <link href='/images/app/splash-1024.png' rel='apple-touch-startup-image' />
      </Head>

      <GoogleTagManagerNoscript clientId={process.env.APP_CONFIG.GOOGLE_ANALYTICS_ID} />

      <Page theme={props.theme || 'default'}>
        <Modal.Modals />
        <Notification.Notifications />
        <div className='App'>
          {props.children}
        </div>
      </Page>
    </>
  );
}

App.Main = function AppMain (props) {
  return (
    <main className={[ 'App__Main', props.padding && 'App__Main--padding' ].join(' ')}>
      {props.children}
    </main>
  );
};

App.Header = function AppHeader (props) {
  return (
    <header className={'App__Header'}>
      {props.children}
    </header>
  );
};

App.Footer = function AppFooter (props) {
  return (
    <footer className={'App__Footer'}>
      {props.children}
    </footer>
  );
};

App.Provider = function AppProvider (props) {
  const { Provider: ModalProvider } = useModal();
  const { Provider: NotificationsProvider } = useNotifications();

  return (
    <>
      <ModalProvider>
        <NotificationsProvider>
          {
            props.authProvider
              ? <props.authProvider>{props.children}</props.authProvider>
              : props.children
          }
        </NotificationsProvider>
      </ModalProvider>
    </>
  );
};
