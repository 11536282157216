import Head from 'next/head';

export default function DocumentTitle (props) {
  const title = process.env.APP_CONFIG.TITLE_TEMPLATE.replace('%t', props.title || process.env.APP_CONFIG.DEFAULT_TITLE);

  return (
    <Head>
      <title>{title}</title>
    </Head>
  );
};
