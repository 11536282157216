import { If, createComponent, toClassName } from '@lib/util/templateHelpers';
import useInput from '@lib/hooks/input';
import Field from './Field';
import Control from './Control';

const textAreaStates = [
  'fixedSize'
];

const Textarea = createComponent('Textarea', { classStates: textAreaStates }, function Textarea ({ mergeClassNames, style }, props) {
  const {
    states, 
    focus,
    blur, 
    change
  } = useInput(props);

  const className = mergeClassNames(toClassName('Textarea', states, props));

  return (
    <textarea
      className={className} 
      style={style}
      value={props.value}
      readOnly={props.readOnly}
      disabled={props.disabled}
      placeholder={props.placeholder}
      onFocus={focus}
      onBlur={blur}
      onChange={change}
    />
  );
});
export default Textarea;

Textarea.Field = createComponent('TextareaField', {}, function TextareaField ({ className, style, slots }, props) {
  const key = props.name;
  const form = props.form;

  return (
    <Field key={`field_${key}`} className={className} style={style}>
      <Field.Label>{slots.label}</Field.Label>
      <Control>
        <Textarea
          key={key}
          type={props.type}
          value={form.data[key]} 
          error={!!form.errors[key]} 
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled}
          onChange={(value) => form.setField(key, value)}
          onFocus={() => form.resetErrors(true)}
          onBlur={(value) => form.validateField(key, value)}
        />
        {
          If(form.errors[key], () => (
            <Field.Help error>{form.errors[key]}</Field.Help>
          ))
          .ElseIf(slots.help, () => (
            <Field.Help>{slots.help}</Field.Help>
          ))
          .EndIf()
        }
      </Control>
    </Field>
  )
});
