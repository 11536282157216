import { createStore } from '@lib/util/store';

// Mutations

function SET_COLLAPSED (state, payload) {
  return {
    collapsed: payload
  }
}

// Actions

export function setCollapsed (payload, mutate) {
  mutate(SET_COLLAPSED, payload);
}

// Export

export const {
  Provider: MobileMenuProvider,
  useStore: useMobileMenuStore
} = createStore({
  defaultState: {
    collapsed: true
  }
});
