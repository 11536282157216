import { clamp } from '@lib/util/math';

export const FIELD_NUMBER = 'number';
export const FIELD_BOOLEAN = 'boolean';
export const FIELD_LIST = 'list';
export const FIELD_ANY = 'value';
export const FIELD_STRING = 'string';
export const FIELD_PASSWORD = 'password';
export const FIELD_PASSWORD_VERIFY = 'passwordVerify';
export const FIELD_EMAIL = 'email';

// TODO: one day bring these in from somewhere for internationalization 
const messages = {
  required: () => 'Required.',
  invalidNumber: () => 'Must be a number.',
  invalidEmail: () => 'Must be a valid email.',
  stringTooShort: (minLength) => `Must be at least ${minLength} characters long.`,
  stringTooLong: (maxLength) => `Must be at most ${maxLength} characters long.`,
  selectAtLeast: (minRequired) => `Select at least ${minRequired} options.`,
  selectAtMost: (maxAllowed) => `Select up to ${maxAllowed} options.`,
  selectBetween: (minRequired, maxAllowed) => `Select between ${minRequired} and ${maxAllowed} options.`,
  passwordMismatch: () => 'Passwords do not match.'
};

export function validateField (value, otherValue, schema) /* (value, schema) */ {
  let error = false;

  if (!schema) {
    schema = otherValue;
  }
  if (!schema) {
    return { value };
  }

  // Number
  if (schema.type === FIELD_NUMBER) {
    value = Number(value);

    if (isNaN(value) || typeof value !== 'number') {
      error = messages.invalidNumber();
    }

    else if (typeof schema.minValue === 'number' || typeof schema.maxValue === 'number') {
      const minValue = (typeof schema.minValue === 'number')
        ? schema.minValue
        : Number.NEGATIVE_INFINITY;

      const maxValue = (typeof schema.maxValue === 'number')
        ? schema.maxValue
        : Number.POSITIVE_INFINITY;

      value = clamp(value, minValue, maxValue);
    }
  }

  // Boolean (like a checkbox)
  else if (schema.type === FIELD_BOOLEAN) {
    if (schema.required) {
      if (typeof schema.trueValue === 'undefined') {
        if (!value) error = messages.required();
      } else {
        if (value !== schema.trueValue) error = messages.required();
      }
    }
  }

  // Array or Object of values
  else if (schema.type === FIELD_LIST) {
    const minRequired = (typeof schema.minRequired === 'number')
      ? schema.minRequired
      : Number.NEGATIVE_INFINITY;

    const maxAllowed = (typeof schema.maxAllowed === 'number')
      ? schema.maxAllowed
      : Number.POSITIVE_INFINITY;

    const numSelected = Array.isArray(value)
      ? value.length
      : Object.keys(value).length;

    if (numSelected < minRequired && typeof schema.maxAllowed === 'undefined') {
      error = messages.selectAtLeast(minRequired);
    } else if (numSelected > maxAllowed && typeof schema.minRequired === 'undefined') {
      error = messages.selectAtMost(maxAllowed);
    } else if (numSelected < minRequired || numSelected > maxAllowed) {
      error = messages.selectBetween(minRequired, maxAllowed);
    }
  }

  // Generic fallback
  else if (schema.type === FIELD_ANY) {
    if (typeof value === 'string') {
      value = value.trim();
    }
    if (schema.required && (value === null || value === undefined || value === '')) {
      error = messages.required();
    }
  }

  // Strings of various sorts
  else if (typeof value === 'string') {
    value = value.trim();

    // Check required
    if (schema.required && !value) {
      error = messages.required();
    } else {
      // Check min/max length
      if ((typeof schema.minLength === 'number' || typeof schema.maxLength === 'number') && schema.type !== FIELD_PASSWORD_VERIFY) {
        const minLength = (typeof schema.minLength === 'number')
          ? schema.minLength
          : Number.NEGATIVE_INFINITY;
  
        const maxLength = (typeof schema.maxLength === 'number')
          ? schema.maxLength
          : Number.POSITIVE_INFINITY;

        if (value.length < minLength) {
          error = messages.stringTooShort(minLength);
        } else if (value.length > maxLength) {
          error = messages.stringTooLong(maxLength);
        }
      }

      if (!error) {
        // Email
        if (schema.type === FIELD_EMAIL) {
          const isValidEmail = !!value.match(/[^\s@]+@[^\s@]+\.[\w]{2,}/g);
          if (!isValidEmail) {
            error = messages.invalidEmail();
          }
        }
        
        // Passwords
        else if (schema.type === FIELD_PASSWORD) {
          if (schema.criteria) {
            const message = []
            const c = schema.criteria;
            
            if (c.specialChars) {
              const match = !!value.match(/[`~!@#$%^&*()_+{}|:"<>?[\]\\;',./_+]/g);
              if (!match) message.push('special character');
            }
            if (c.upperAndLower) {
              const matchLower = value.match(/[a-z]/g);
              const matchUpper = value.match(/[A-Z]/g);
              if (!matchLower || !matchUpper) message.push('lowercase and uppercase character')
            }
            if (c.number) {
              const match = !!value.match(/\d/g);
              if (!match) message.push('number')
            }

            if (message.length) {
              error = 'Must have at least one: ' + message.join(', ') + '.'
            }
          }
        }
        
        // Verify password
        else if (schema.type === FIELD_PASSWORD_VERIFY) {
          if (value !== otherValue) {
            error = messages.passwordMismatch();
          }
        }
      }
    }
  }

  return { error, value };
}

export function validateForm (formData, formSchema) {
  return Object.keys(formData).reduce((acc, key) => {
    const value = formData[key];
    const schema = formSchema[key].schema;

    let result;

    if (!schema) {
      result = { value };
    } else {
      let otherValue
      if (schema.type === FIELD_PASSWORD_VERIFY) {
        otherValue = formData[schema.checkAgainst];
      }
      result = validateField(value, otherValue, schema);
    }

    acc.errors[key] = result.error;
    acc.values[key] = result.value;

    return acc;
  }, { errors: {}, values: {} });
}
