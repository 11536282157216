import { createContext, useContext, useEffect, useReducer } from 'react';

import storage from '@lib/util/storage';

export const CACHE_STRATEGY_LOCAL = Symbol();
export const CACHE_STRATEGY_COOKIE = Symbol();

export function createStore (options = {}) {
  const {
    cacheKey,
    cacheOptions,
    cacheStrategy,
    defaultState
  } = options;

  const store = createContext({
    state: defaultState || null,
    setState: null,
    dispatch: null
  });

  return {
    context: store,

    useStore () {
      const { state, setState, dispatch } = useContext(store);
      return [ state, dispatch, setState ];
    },

    Provider (props) {
      // Every time the store is updated, cache the state locally
      let storeLocally
      if (process.browser && cacheStrategy) {
        let serializeDebounce = null;

        storeLocally = (state) => {
          clearTimeout(serializeDebounce);
          serializeDebounce = setTimeout(() => {
            switch (cacheStrategy) {
              case CACHE_STRATEGY_LOCAL:
                storage.setLocal(cacheKey, state);
                break;
              case CACHE_STRATEGY_SESSION:
                storage.setSession(cacheKey, state);
                break;
              case CACHE_STRATEGY_COOKIE:
                storage.setCookie(cacheKey, state, cacheOptions);
                break;
            }
          }, process.env.NEXT_PUBLIC_LOCAL_STORE_TIMEOUT || 300);
        }
      }

      // Reducer
      const [ state, dispatchState ] = useReducer((state, { mutation, payload, setState }) => {
        let newState
        if (setState) {
          newState = setState
        } else {
          newState = {
            ...state,
            ...mutation(state, payload)
          }
        }

        if (storeLocally) {
          storeLocally(newState)
        }

        return newState
      }, props.initialState || defaultState);

      // Dispatchers
      const mutate = (mutation, payload) => {
        dispatchState({
          mutation,
          payload
        });
      };
      const dispatch = (action, options) => {
        return action(options, mutate, state);
      };

      // Check for cached state locally and populate store if it exists
      if (process.browser && cacheStrategy) {
        useEffect(() => {
          let state
          switch (cacheStrategy) {
            case CACHE_STRATEGY_LOCAL:
              state = storage.getLocal(cacheKey);
              break;
            case CACHE_STRATEGY_SESSION:
              state = storage.getSession(cacheKey);
              break;
            case CACHE_STRATEGY_COOKIE:
              state = storage.getCookie(cacheKey);
              break;
          }

          if (state && !state.error) {
            dispatchState({
              setState: state
            });
          }
        }, []);
      }

      // It is with great hesitation I make this available.
      // In general you should _never_ use this as it will cause
      // the entire component tree to rerender, which is bad.
      // Please don't use this.
      const setState = function (newState) {
        dispatchState({
          setState: newState
        });
      };

      return (
        <store.Provider value={{ state, setState, dispatch }}>
          {props.children}
        </store.Provider>
      );
    }
  }
}
